import { CHANGE_STATUS_SUIVI, CHANGE_PAGE, CHANGE_ISREVERSE, CHANGE_DEB, CHANGE_NB_DEVIS, CHANGE_FIND,CHANGE_STYLE_RETOUR, CHANGE_DETAIL, CHANGE_TAB_DATA1, CHANGE_TAB_DATA2, CHANGE_TAB_DATA3, CHANGE_TAB_GROUPE, CHANGE_PROFIL_ADM, CHANGE_LOGO_ADM, CHANGE_ALL_USERS, CHANGE_ALL_LOGOS, CHANGE_PRESTA, CHANGE_CHOIXADM, CHANGE_IMGGET, CHANGE_IMG, CHANGE_FACTURE, CHANGE_ADM_PAGE, CHANGE_CHARGE, CHANGE_COLORD, CHANGE_COLORC, CHANGE_COLORF, CHANGE_COLORR, CHANGE_FT, CHANGE_RT, CHANGE_SUIVI_PAGE, CHANGE_BOOLP, CHANGE_TITLE, CHANGE_STYLEPROFIL, CHANGE_STYLED, CHANGE_STYLER, CHANGE_STYLEP, CHANGE_STYLEC, CHANGE_STYLEF, CHANGE_PROFILE, CHANGE_COMMANDE, CHANGE_DEVIS, CHANGE_AUTH, CHANGE_CT, CHANGE_DT } from "../actions/action-types";
import React from 'react';
import Accueil from '../components/Accueil';
import Log_adm from '../components/Log_adm';
import Create_user from '../components/Create_user';
import Commande from '../components/Commande';
import logo from '../img/icon_D.png';

const initialState = {
  suivi_status: <Accueil  key={"accueil"} />,
  suivi_page: <Commande  key={"commande"} />,
  title_header:'',
  style_d:{backgroundColor:"#f5f6f8"},
  style_c:{backgroundColor:"#f5f6f8"},
  style_p:{backgroundColor:"#f5f6f8"},
  style_f:{backgroundColor:"#f5f6f8"},
  style_r:{backgroundColor:"#f5f6f8"},
  ct:false,
  dt:false,
  ft:false,
  rt:false,
  style_profil:{display:"none"},
  profile_user:[],
  commande_user:[],
  devis_user:[],
  facture_user:[],
  presta_user:[],
  img_list:[],
  charge:false,
  auth:localStorage.getItem('user'),
  boolp:false,
  colord:true,
  colorc:true,
  colorf:true,
  colorr:true,
  adm_page: <Log_adm  key={"log_adm"} />,
  img_get:null,
  choix_adm: <Create_user  key={"create_user"} />,
  all_users:[],
  all_logos:[],
  logo_adm:[],
  profil_adm:[],
  tabGroupe:[],
  tabData1:[],
  tabData2:[],
  tabData3:[],
  detail_cmd:[],
  deb:-5,
  nb_devis:-5,
  find:-5,
  style_retour:{display:"none"},
  page:0,
  is_reverse:false

};

function rootReducer(state = initialState, action) {

  if (action.type === CHANGE_DETAIL) {
    return Object.assign({}, state, {
      detail_cmd: action.payload
    });
  }

  if (action.type === CHANGE_PAGE) {
    return Object.assign({}, state, {
      page: action.payload
    });
  }

  if (action.type === CHANGE_ISREVERSE) {
    return Object.assign({}, state, {
      is_reverse: action.payload
    });
  }

  if (action.type === CHANGE_DEB) {
    return Object.assign({}, state, {
      deb: action.payload
    });
  }

  if (action.type === CHANGE_NB_DEVIS) {
    return Object.assign({}, state, {
      nb_devis: action.payload
    });
  }

  if (action.type === CHANGE_FIND) {
    return Object.assign({}, state, {
      find: action.payload
    });
  }

  if (action.type === CHANGE_STYLE_RETOUR) {
    return Object.assign({}, state, {
      style_retour: action.payload
    });
  }

  if (action.type === CHANGE_PROFIL_ADM) {
    return Object.assign({}, state, {
      profil_adm: action.payload
    });
  }

  if (action.type === CHANGE_TAB_DATA1) {
    return Object.assign({}, state, {
      tabData1: action.payload
    });
  }

  if (action.type === CHANGE_TAB_DATA2) {
    return Object.assign({}, state, {
      tabData2: action.payload
    });
  }

  if (action.type === CHANGE_TAB_DATA3) {
    return Object.assign({}, state, {
      tabData3: action.payload
    });
  }

  if (action.type === CHANGE_TAB_GROUPE) {
    return Object.assign({}, state, {
      tabGroupe: action.payload
    });
  }

  if (action.type === CHANGE_LOGO_ADM) {
    return Object.assign({}, state, {
      logo_adm: action.payload
    });
  }

  if (action.type === CHANGE_ALL_LOGOS) {
    return Object.assign({}, state, {
      all_logos: action.payload
    });
  }

  if (action.type === CHANGE_ALL_USERS) {
    return Object.assign({}, state, {
      all_users: action.payload
    });
  }

  if (action.type === CHANGE_CHOIXADM) {
    return Object.assign({}, state, {
      choix_adm: action.payload
    });
  }

  if (action.type === CHANGE_IMGGET) {
    return Object.assign({}, state, {
      img_get: action.payload
    });
  }
  if (action.type === CHANGE_ADM_PAGE) {
    return Object.assign({}, state, {
      adm_page: action.payload
    });
  }
  if (action.type === CHANGE_STATUS_SUIVI) {
    return Object.assign({}, state, {
      suivi_status: action.payload
    });
  }
  if (action.type === CHANGE_SUIVI_PAGE) {
    return Object.assign({}, state, {
      suivi_page: action.payload
    });
  }

  if (action.type === CHANGE_COLORD) {
    return Object.assign({}, state, {
      colord: action.payload
    });
  }

   if (action.type === CHANGE_COLORR) {
    return Object.assign({}, state, {
      colorr: action.payload
    });
  }

  if (action.type === CHANGE_COLORC) {
    return Object.assign({}, state, {
      colorc: action.payload
    });
  }

  if (action.type === CHANGE_COLORF) {
    return Object.assign({}, state, {
      colorf: action.payload
    });
  }

  if (action.type === CHANGE_FT) {
    return Object.assign({}, state, {
      ft: action.payload
    });
  }


 if (action.type === CHANGE_RT) {
    return Object.assign({}, state, {
      rt: action.payload
    });
  }


  if (action.type === CHANGE_STYLEF) {
    return Object.assign({}, state, {
      style_f: action.payload
    });
  }

    if (action.type === CHANGE_STYLER) {
    return Object.assign({}, state, {
      style_r: action.payload
    });
  }

  if (action.type === CHANGE_BOOLP) {
    return Object.assign({}, state, {
      boolp: action.payload
    });
  }

  if (action.type === CHANGE_PRESTA) {
    return Object.assign({}, state, {
      presta_user: action.payload
    });
  }

  if (action.type === CHANGE_IMG) {
    return Object.assign({}, state, {
      img_list: action.payload
    });
  }

   if (action.type === CHANGE_CHARGE) {
    return Object.assign({}, state, {
      charge: action.payload
    });
  }

  if (action.type === CHANGE_TITLE) {
    return Object.assign({}, state, {
      title_header: action.payload
    });
  }

  if (action.type === CHANGE_STYLED) {
    return Object.assign({}, state, {
      style_d: action.payload
    });
  }

  if (action.type === CHANGE_STYLEP) {
    return Object.assign({}, state, {
      style_p: action.payload
    });
  }

  if (action.type === CHANGE_CT) {
    return Object.assign({}, state, {
      ct: action.payload
    });
  }

  if (action.type === CHANGE_DT) {
    return Object.assign({}, state, {
      dt: action.payload
    });
  }


  if (action.type === CHANGE_STYLEPROFIL) {
    return Object.assign({}, state, {
      style_profil: action.payload
    });
  }

  if (action.type === CHANGE_STYLEC) {
    return Object.assign({}, state, {
      style_c: action.payload
    });
  }

  if (action.type === CHANGE_PROFILE) {
    return Object.assign({}, state, {
      profile_user: action.payload
    });
  }

  if (action.type === CHANGE_COMMANDE) {
    return Object.assign({}, state, {
      commande_user: action.payload
    });
  }

  if (action.type === CHANGE_DEVIS) {
    return Object.assign({}, state, {
      devis_user: action.payload
    });
  }

  if (action.type === CHANGE_FACTURE) {
    return Object.assign({}, state, {
      facture_user: action.payload
    });
  }

  if (action.type === CHANGE_AUTH) {
    return Object.assign({}, state, {
      auth: action.payload
    });
  }
  
  return state;
}
export default rootReducer;