import React, { Component } from 'react';
import '../css/profil.css';
import '../css/Login.css';
import '../css/Create_user.css';
import { connect } from "react-redux";
import List_users from './List_users';
import logo_oeil from '../img/eye.png';
import logo_no_oeil from '../img/no-visibility.png';
import { register, sendImg, update_user} from '../api/UserFunctions';

import { changeAllUsers, changeChoixAdm } from "../actions/index";
function mapDispatchToProps(dispatch) {
    return {
      changeAllUsers:all_users => dispatch(changeAllUsers(all_users)),
      changeChoixAdm: choix_adm => dispatch(changeChoixAdm(choix_adm))
    };
}

const bcrypt = require('bcryptjs');


const mapStateToProps = state => {
  return { profil_adm: state.profil_adm, all_users: state.all_users };
};

class Update_user extends Component {


  constructor(props){
    super(props);
    this.state = {
      value_res:'',
      test1:true,
      test2:true,
      login: this.props.admprofil[0].login,
      password: '',
      selectedFile: null
    }
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handlePass1 = this.handlePass1.bind(this);
    this.handlePass1P = this.handlePass1P.bind(this);
    this.handlePass2 = this.handlePass2.bind(this);
    this.handlePass2P = this.handlePass2P.bind(this);
    this.handleAnnul = this.handleAnnul.bind(this);
  }


  onSubmit(e) {
      e.preventDefault();

      if(this.state.login.localeCompare("")!==0){

          var user = {};
         

              if(this.state.password.localeCompare("")==0){
                user = {
                  id:this.props.admprofil[0].id,
                  login: this.state.login,
                  status: "Technicien",
                }
              }
              else{
                user = {
                  id:this.props.admprofil[0].id,
                  login: this.state.login,
                  password: this.state.password,
                  status: "Technicien",
                }
              }

              
              update_user(user).then(res => {
                console.log("update : "+this.props.all_users);
                let newArray = [];
                this.props.all_users.forEach((userdata,index) => {
                  if(this.props.admprofil[0].id === userdata.id)
                  {
                    newArray.push(user);
                  }
                  else{
                    newArray.push(userdata);
                  }
                });
                this.props.changeAllUsers(newArray);
                this.props.changeChoixAdm(<List_users  key={"list_user"} />);
              });

      }
      else{
        this.setState({value_res:"L'email ne peut pas être vide"});
      }
    }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handlePass1(e){
    this.setState({pass1:'text'});
    this.setState({test1:false});
  }

  handlePass1P(e){
    this.setState({pass1:'password'});
    this.setState({test1:true});
  }

   handlePass2(e){
    this.setState({pass2:'text'});
    this.setState({test2:false});
  }

  handlePass2P(e){
    this.setState({pass2:'password'});
    this.setState({test2:true});
  }

  handleAnnul(e){
    this.props.changeChoixAdm(<List_users  key={"list_user"} />);
  }

  onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
     
    }; 



  render() {
    return (

    	<div className="create_user">
        <div id="block1">
          <div id='img_p'>
          <form className="form_profile" id="profile2" onSubmit={this.onSubmit}>
                <p className="p_info">Login&nbsp;&nbsp;&nbsp;</p>
                  <input
                    id="form_profile_mdp22"
                    type="text"
                    name="login"
                    placeholder="Login"
                    value={this.state.login}
                    onChange={this.onChange}
                  />
                <br/>
                <p className="p_info">Mot de passe&nbsp;&nbsp;&nbsp;</p>
                <input
                  id="form_profile_mdp22"
                  type="password"
                  name="password"
                  placeholder="Mot de passe"
                  value={this.state.password}
                  onChange={this.onChange}
                />
                <br/>
                <br />
                <button id="form_button" type="submit">Modifier</button><button onClick={(event) => this.handleAnnul(event)} id="form_button" >Annuler</button>
                <p>{this.state.value_res.toString()}</p>
              </form>

              
          </div>
        </div>
		  </div>
      );
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Update_user);


