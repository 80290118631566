import React, { Component } from 'react';
import '../css/profil.css';
import '../css/Login.css';
import '../css/Create_user.css';
import { connect } from "react-redux";
import logo_oeil from '../img/eye.png';
import logo_no_oeil from '../img/no-visibility.png';
import { register, sendImg } from '../api/UserFunctions';

const bcrypt = require('bcryptjs');


const mapStateToProps = state => {
  return { profile_user: state.profile_user, all_logos:state.all_logos };
};

class Create_user extends Component {


  constructor(props){
    super(props);
    this.state = {
      value_res:'',
      test1:true,
      test2:true,
      login: '',
      password: '',
      selectedFile: null,
      contract_date: null
    }
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handlePass1 = this.handlePass1.bind(this);
    this.handlePass1P = this.handlePass1P.bind(this);
    this.handlePass2 = this.handlePass2.bind(this);
    this.handlePass2P = this.handlePass2P.bind(this);
    this.renderTable = this.renderTable.bind(this);
  }


  onSubmit(e) {
      e.preventDefault();

      if(this.state.login.localeCompare("")!==0 && this.state.password.localeCompare("")!==0){

          const user = {
                login: this.state.login,
                password: this.state.password,
                status: "Technicien",
              }

              
              register(user).then(res => {
                
                var keys = Object.keys(res);
                if(keys[0].localeCompare("error")==0){
                  this.setState({value_res:"Erreur, le login inscrit existe déjà"});
                }
                else{
                  
                  this.setState({value_res:"L'utilisateur "+this.state.login+" a bien été enregistré"});
                }
              });

      }
      else{
        this.setState({value_res:"Le login et le mot de passe ne peuvent être vides"});
      }
    }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  renderTable() {
    return this.props.all_logos.map((logo,index) => {

      return(
        <option id={index} value={logo.etps}>{logo.etps}</option>
      )

    });

  }

  handlePass1(e){
    this.setState({pass1:'text'});
    this.setState({test1:false});
  }

  handlePass1P(e){
    this.setState({pass1:'password'});
    this.setState({test1:true});
  }

   handlePass2(e){
    this.setState({pass2:'text'});
    this.setState({test2:false});
  }

  handlePass2P(e){
    this.setState({pass2:'password'});
    this.setState({test2:true});
  }

  onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
     
    }; 



  render() {
    return (

    	<div className="create_user">
        <div id="block1">
          <div id='img_p'>
          <form className="form_profile" id="profile2" onSubmit={this.onSubmit}>
                <input
                  id="form_profile_mdp22"
                  type="text"
                  name="login"
                  placeholder="Login"
                  value={this.state.login}
                  onChange={this.onChange}
                />
                <br/>
                <input
                  id="form_profile_mdp22"
                  type="password"
                  name="password"
                  placeholder="Mot de passe"
                  value={this.state.password}
                  onChange={this.onChange}
                />
                <br />
                <button id="form_button" type="submit">Envoyer</button>
                <p>{this.state.value_res.toString()}</p>
              </form>
          </div>
        </div>
		  </div>
      );
    }
  }
export default connect(mapStateToProps)(Create_user);


