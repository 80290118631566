import React, { Component } from 'react';
import '../css/Devis.css';
import { connect } from "react-redux";
import back from '../img/back.svg';
import next from '../img/next.svg';
import back2 from '../img/backback.svg';
import next2 from '../img/nextnext.svg';
import Commande from './Commande';
import close from '../img/close.png';
import logo_oeil from '../img/eye_black.png';
import fleche from '../img/fleche.png';
import ReactTable from 'react-table';
import ReactPaginate from 'react-paginate';
import { changeStyleRetour,changeSuiviP, changeTitle, changeStyleProfil, changeColorC, changeColorD, changeBoolP, changeStyleD, changeStyleC, changeCT, changeDT} from "../actions/index";




function mapDispatchToProps(dispatch) {
    return {
      changeTitle: title_header => dispatch(changeTitle(title_header)),
      changeSuiviP: suivi_page => dispatch(changeSuiviP(suivi_page)),
      changeStyleProfil: style_profil => dispatch(changeStyleProfil(style_profil)),
      changeStyleD: style_d => dispatch(changeStyleD(style_d)),
      changeStyleC: style_c => dispatch(changeStyleC(style_c)),
      changeDT: dt => dispatch(changeDT(dt)),
      changeCT: ct => dispatch(changeCT(ct)),
      changeBoolP: boolp => dispatch(changeBoolP(boolp)),
      changeColorD: colord => dispatch(changeColorD(colord)),
      changeColorC: colorc => dispatch(changeColorC(colorc)),
      changeStyleRetour: style_retour => dispatch(changeStyleRetour(style_retour))
    };
}

const mapStateToProps = state => {
  return { devis_user:state.devis_user,commande_user:state.commande_user, profile_user: state.profile_user   };
};





class Devis extends Component {

 constructor(props){
    super(props);
    this.state = {
      deb: 0,
      nb_devis:15,
      search:false,
      find:'',
      style_tr:{backgroundColor:"#FDDDDD"},
      test:false,
      triage:0,
      page:0,
      is_reverse:false,
      reversed: [],
      options: {
        
        chart: {
          tools: {
          download: '<img src="/static/icons/download.png" class="ico-download" width="200">',
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
          id: 'proviso_chartt',
          defaultLocale: 'fr',
          locales: [{
            name: 'fr',
            options: {
              months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
              shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
              shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
              toolbar: {
                "exportToSVG": "Exporter SVG",
                "exportToPNG": "Exporter PNG",
                "exportToCSV": "Exporter CSV",
                selection: 'Selection',
                selectionZoom: 'Selection Zoom',
                zoomIn: 'Zoom In',
                zoomOut: 'Zoom Out',
                pan: 'Panning',
                reset: 'Reset Zoom',
              }
            }
          }]
        },
        title:{
          text:"Proviso",
        },
         plotOptions: {
            bar: {
                distributed: true
            }
          },
        xaxis: {
          categories: ['Devis - Janvier', 'Commande - Janvier', 'Facture - Janvier','Devis - Février','Commande - Février','Facture - Février'],
          tooltip: {
              enabled: false,
          },

        },
        legend:{
          show:false
        },
        colors: ['#41B883', '#E46651', 'black']
      },
      series: [{
        name: "",
        data: [30, 40, 35, 40, 50, 45],
      }]
    }
    this.handlePageS2 = this.handlePageS2.bind(this);
    this.handlePageP2 = this.handlePageP2.bind(this);
    this.handlePageS = this.handlePageS.bind(this);
    this.handlePageP = this.handlePageP.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSuppr = this.handleSuppr.bind(this);
  }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
    this.setState({search:true});
  }

  componentDidMount(){
    var reversed = this.props.devis_user;
    const reverseArray = (array) => {
      const revArray = array.reverse();
      return [...revArray] 
    }
    reversed.reverse();
    this.setState({reversed:reversed.reverse()});
  }




  formatDate(date){
    if(typeof(date)==='number'){
      date=date.toString();
    }
    if(date.length > 5){
        let year = date.substring(0,4);
        let month = date.substring(4,6);
        let day = date.substring(6,8);

    return day+"/"+month+"/"+year;
    }

    return '';
    
   
  }

  renderTable(page){
    if(this.props.devis_user.length>0){

      let affich = this.props.devis_user.length-1-page;
    //console.log("rerender "+this.state.is_reverse+" "+this.state.page+" "+affich);
    let test = false;
    let style_tr = {backgroundColor:"lightgreen"};
    if(this.state.search){
      return this.renderTable2();          
    }
    else{
      if(this.state.is_reverse){
        
        var renderReverse = this.props.devis_user;
        //var renderReverse2 = renderReverse.reverse();
        //console.log("reverse "+JSON.stringify(this.props.devis_user[this.state.page]));
        return [...this.props.devis_user].reverse()[this.state.page].sort(function compare(b,a) {
          var string1 = a.Date_Crea_Devis.toString();
          var string2 = b.Date_Crea_Devis.toString();
          var dateA = new Date(parseInt(string1.substring(0,4)), parseInt(string1.substring(4,6)),parseInt(string1.substring(6,8)));
          var dateB = new Date(parseInt(string2.substring(0,4)), parseInt(string2.substring(4,6)),parseInt(string2.substring(6,8)));
        return dateA - dateB;
        }).map((devis,index) => {
          const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Devis, Date_Crea_Devis, Date_Validite} = devis;
          var eff = Date_Validite;
          this.props.commande_user.forEach((commande,index) => {
            commande.forEach((commande,index) => {
            if(commande["Num_Devis"].localeCompare(Num_Devis)===0 ){
              test = true;
            }
          });
          });
          if(test){
            test = false;
              return (
                <tr className="test" key={id} onClick={() => this.handleC(Num_Devis)} title="Voir commande correspondante">
                  <td>{Client_Groupe}</td>
                  <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                  <td>{Code_Client}</td>
                  <td style={Object.assign({}, {textAlign:"left"})} >{Num_Devis}</td>
                  <td>{this.formatDate(Date_Crea_Devis)}</td>
                  {eff==0?<td></td>:<td>{this.formatDate(Date_Validite)}</td>}
                  <td style={{color:"green"}}>Validé</td>
                </tr>
              )
          }
          else{
              return (
                <tr key={id} >
                  <td>{Client_Groupe}</td>
                  <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                  <td>{Code_Client}</td>
                  <td style={{textAlign:"left"}}>{Num_Devis}</td>
                  <td>{this.formatDate(Date_Crea_Devis)}</td>
                  {eff==0?<td></td>:<td>{this.formatDate(Date_Validite)}</td>}
      <td>En cours</td>
                </tr>
              )
          }
          return null;
      });
      }
      else{
        console.log("not reverse "+JSON.stringify(this.props.devis_user));
        return this.props.devis_user[this.state.page].map((devis,index) => {
          const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Devis, Date_Crea_Devis, Date_Validite} = devis;
          var eff = Date_Validite;
          this.props.commande_user.forEach((commande,index) => {
            commande.forEach((commande,index) => {
            if(commande["Num_Devis"].localeCompare(Num_Devis)===0 ){
              test = true;
            }
          });
          });
          if(test){
            test = false;
              return (
                <tr className="test" key={id} onClick={() => this.handleC(Num_Devis)} title="Voir commande correspondante">
                  <td>{Client_Groupe}</td>
                  <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                  <td>{Code_Client}</td>
                  <td style={Object.assign({}, {textAlign:"left"})} >{Num_Devis}</td>
                  <td>{this.formatDate(Date_Crea_Devis)}</td>
                  {eff==0?<td></td>:<td>{this.formatDate(Date_Validite)}</td>}
      <td style={{color:"green"}}>Validé</td>
      
                </tr>
              )
            
          }
          else{
              return (
                <tr key={id} >
                  <td>{Client_Groupe}</td>
                  <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                  <td>{Code_Client}</td>
                  <td style={{textAlign:"left"}}>{Num_Devis}</td>
                  <td>{this.formatDate(Date_Crea_Devis)}</td>
                  {eff==0?<td></td>:<td>{this.formatDate(Date_Validite)}</td>}
      <td>En cours</td>
                </tr>
              )
            
          }
          return null;
      });
      }
      
    }
    }
    else{
      return null;
    }
    
    
  }

  handleC(data){
    this.props.changeStyleRetour({display:"none"});
    this.props.changeCT(true);
    this.props.changeDT(false);
    this.props.changeTitle( 'Commandes' );
    this.props.changeSuiviP( <Commande  key={"commande"} dataD={data}/> );
    this.props.changeStyleProfil({display:"none"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeBoolP(false);
    this.props.changeColorC(false);
    this.props.changeColorD(true);
  }

  handleSuppr(event){

    this.setState({find:''});
   
  }

  renderTable2(){
    var nb = this.state.nb_devis;
    var nb2 = this.state.deb;
   let test = false;
    let mapping = [];
    let i = 0;
    let style_tr = {backgroundColor:"lightgreen"};
    this.props.devis_user.forEach((devis,index) => {
      devis.forEach((devis,index) => {
        const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Devis, Date_Crea_Devis, Date_Validite} = devis;
        if((Client_Groupe.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Libelle_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Code_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Devis.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || this.formatDate(Date_Crea_Devis).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Devis.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || this.formatDate(Date_Validite).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1) && id)
        {
          mapping[i] = devis;
          i++;
        }
      });
     
    });
    while((nb-mapping.length)>15){
      nb-=15;
      nb2-=15;
    }
    return mapping.map((devis,index) => {
        const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Devis, Date_Crea_Devis, Date_Validite} = devis;
        var eff = Date_Validite;
      this.props.commande_user.forEach((commande,index) => {
        commande.forEach((commande,index) => {
          if(commande["Num_Devis"].localeCompare(Num_Devis)===0 ){
            test = true;
          }
        });
        });
      if(Client_Groupe.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Libelle_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Code_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Devis.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || this.formatDate(Date_Crea_Devis).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Devis.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || this.formatDate(Date_Validite).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 )
      {

        
          if(test){
            test = false
            if(index<nb && index>=nb2){ 
              
              return (
                <tr className="test" key={id} onClick={() => this.handleC(Num_Devis)} title="Voir commande correspondante">
                  <td>{Client_Groupe}</td>
                  <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                  <td>{Code_Client}</td>
                  <td style={Object.assign({}, {textAlign:"left"})} >{Num_Devis}</td>
                  <td>{this.formatDate(Date_Crea_Devis)}</td>
                  {eff==0?<td></td>:<td>{this.formatDate(Date_Validite)}</td>}
      <td style={{color:"green"}}>Validé</td>
      
                </tr>
              )
            }
          }
          else{
            console.log(" avant "+index);
            if(index<nb && index>=nb2){ 
              console.log(index);
              return (
                <tr key={id} >
                  <td>{Client_Groupe}</td>
                  <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                  <td>{Code_Client}</td>
                  <td style={{textAlign:"left"}}>{Num_Devis}</td>
                  <td>{this.formatDate(Date_Crea_Devis)}</td>
                  {eff=0?<td></td>:<td>{this.formatDate(Date_Validite)}</td>}
                  <td>En cours</td>
                </tr>
              )
            }
        }
        return null;
      }
      return null;
      });
  }


  handlePageS2(){
      this.setState({deb:this.props.devis_user.length*15-15});
      this.setState({nb_devis:this.props.devis_user.length*15});
    
      this.setState({page:this.props.devis_user.length-1});
  }

  handlePageP2(){
    console.log(("p2"))
      this.setState({deb:0});
      this.setState({nb_devis:15}); 
      if(this.state.page !==0 ){
        this.setState({page:0});
      }
      
  }
 
 handlePageS(){
   if(this.state.nb_devis < this.props.devis_user.length*15){
      let nb_deb = this.state.deb;
      let nb_fin = this.state.nb_devis;
      this.setState({deb:nb_deb+15});
      this.setState({nb_devis:nb_fin+15});
    }

    if(this.state.page < this.props.devis_user.length-1){
      this.setState({page:this.state.page+1});
    }
    
  }

  handlePageP(){
    if(this.state.page > 0){
      this.setState({page:this.state.page-1});
    }
    if(this.state.deb >= 15){
      let nb_deb = this.state.deb;
      let nb_fin = this.state.nb_devis;
      this.setState({deb:nb_deb-15});
      this.setState({nb_devis:nb_fin-15});
    }
   
  }

  handleTri(){
    /*
    if(this.state.triage === 0){
        this.props.devis_user.sort(function compare(a,b) {
            var string1 = a.Date_Crea_Devis.toString();
            var string2 = b.Date_Crea_Devis.toString();
            var dateA = new Date(parseInt(string1.substring(0,4)), parseInt(string1.substring(4,6)),parseInt(string1.substring(6,8)));
            var dateB = new Date(parseInt(string2.substring(0,4)), parseInt(string2.substring(4,6)),parseInt(string2.substring(6,8)));
        return dateA - dateB;
        });
        this.setState({triage:1});
    }
    else{
        this.props.devis_user.sort(function compare(b,a) {
            var string1 = a.Date_Crea_Devis.toString();
            var string2 = b.Date_Crea_Devis.toString();
            var dateA = new Date(parseInt(string1.substring(0,4)), parseInt(string1.substring(4,6)),parseInt(string1.substring(6,8)));
            var dateB = new Date(parseInt(string2.substring(0,4)), parseInt(string2.substring(4,6)),parseInt(string2.substring(6,8)));
        return dateA - dateB;
        });

        this.setState({triage:0});
    }*/
    this.setState({page:0});
    this.setState({is_reverse:!this.state.is_reverse, search:false,find:''});
    

    }

  render() {

    
    
    return (
    <div className="devis">
    <input
        id="search"
        type="text"
        placeholder="Recherche"
        name="find"
        value={this.state.find}
        onChange={this.onChange}
      /> {this.state.find.localeCompare("")==0?null:<img id="croix_cmd3" onClick={(event) => this.handleSuppr(event)} src={close} alt="croix"/>}
      <table id="t1" >
        <thead>
          <tr>
            <th>GROUPE</th>
            <th>LIBELL&Eacute; CLIENT</th> 
            <th>CODE CLIENT</th>
            <th>N° DEVIS</th>
            <th onClick={(event) => this.handleTri(event)} style={{cursor:"pointer"}}><span title="Tri par date">CR&Eacute;ATION DU DEVIS<img  id="fleche1" src={fleche} alt="fleche"/></span></th>
            <th>DATE DE VALIDIT&Eacute;</th>
	    <th>STATUT DU DEVIS</th>
          </tr>
        </thead>
        <tbody>
            {this.renderTable(this.state.page)}
        </tbody>
      </table>
      <div id="page_d">
      <img id="pagePD" onClick={(event) => this.handlePageP2(event)} src={back2} alt="Logo back"/>
      <img id="pagePD22" onClick={(event) => this.handlePageP(event)} src={back} alt="Logo back"/>
        <img id="pageSD22" onClick={(event) => this.handlePageS(event)} src={next} alt="Logo next"/>
        <img id="pageSD" onClick={(event) => this.handlePageS2(event)} src={next2} alt="Logo next"/>
      </div>


     
    
     
    </div>
      );
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Devis);
