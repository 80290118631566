import React, { Component } from 'react';
import '../css/Devis.css';
import { connect } from "react-redux";
import back from '../img/back.svg';
import next from '../img/next.svg';
import back2 from '../img/backback.svg';
import next2 from '../img/nextnext.svg';
import Commande from './Commande';
import close from '../img/close.png';
import fleche from '../img/fleche.png';
import logo_oeil from '../img/eye_black.png';
import { changeSuiviP, changeTitle, changeStyleProfil, changeColorC, changeColorD, changeBoolP, changeStyleD, changeStyleC, changeCT, changeDT} from "../actions/index";


function mapDispatchToProps(dispatch) {
    return {
      changeTitle: title_header => dispatch(changeTitle(title_header)),
      changeSuiviP: suivi_page => dispatch(changeSuiviP(suivi_page)),
      changeStyleProfil: style_profil => dispatch(changeStyleProfil(style_profil)),
      changeStyleD: style_d => dispatch(changeStyleD(style_d)),
      changeStyleC: style_c => dispatch(changeStyleC(style_c)),
      changeDT: dt => dispatch(changeDT(dt)),
      changeCT: ct => dispatch(changeCT(ct)),
      changeBoolP: boolp => dispatch(changeBoolP(boolp)),
      changeColorD: colord => dispatch(changeColorD(colord)),
      changeColorC: colorc => dispatch(changeColorC(colorc))
    };
}

const mapStateToProps = state => {
  return { facture_user:state.facture_user,commande_user:state.commande_user, profile_user: state.profile_user   };
};



class Facture extends Component {

 constructor(props){
    super(props);
    this.state = {
      deb: 0,
      nb_devis:12,
      search:false,
      find:'',
      triage:0,
      page:0,
      is_reverse:false,
      style_tr:{backgroundColor:"#fafafa"},
      test:false,
      data1:0,
      data2:0,
      data3:0,
      data4:0,
      data5:0,
      data6:0,
      data7:0
    }
    this.handlePageS = this.handlePageS.bind(this);
    this.handlePageP = this.handlePageP.bind(this);
    this.handlePageS2 = this.handlePageS2.bind(this);
    this.handlePageP2 = this.handlePageP2.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSuppr = this.handleSuppr.bind(this);
  }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
    this.setState({search:true});
  }




  formatDate(date){
    if(typeof(date)==='number'){
      date=date.toString();
    }
    if(date.length > 5){
        let year = date.substring(0,4);
        let month = date.substring(4,6);
        let day = date.substring(6,8);

    return day+"/"+month+"/"+year;
    }

    return '';
    
   
  
  }

  componentDidMount(){
    var data1 = 0;
    var data2 = 0;
    var data3 = 0;
    var data4 = 0;
    var data5 = 0;
    var data6 = 0;
    var data7 = 0;
    this.props.facture_user.forEach((facture,index) => {
      facture.forEach((facture,index) => {
        const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Num_Facture, Montant_Facture, Date_Comptable, Date_Echeance} = facture;
        var date_eche = new Date(Date_Echeance.substring(0,4),Date_Echeance.substring(4,6)-1,Date_Echeance.substring(6,8));
        var diff = new Date() - date_eche;
        diff = diff/(1000 * 60 * 60 * 24);
        
        if(diff < 30 && diff > 0 ){
          data3+=parseFloat(Montant_Facture);
            

        }
        else if(diff >= 30 && diff < 60 ){
          data4+=parseFloat(Montant_Facture);
          

        }
        else if(diff >= 60 ){
          data5+=parseFloat(Montant_Facture);
        

        }
        else{
          data2+=parseFloat(Montant_Facture);
        }
      });
    });

    this.setState({data1,data2,data3,data4,data5,data6:data3+data4+data5,data7:data1+data2+data3+data4+data5+data6});

  }

  renderTable(page){
    if(this.props.facture_user.length>0){
      let test = false;
      let style_tr = {backgroundColor:"lightgreen"};
      if(this.state.search){
        return this.renderTable2();          
      }
      else{
        if(this.state.is_reverse){
          return [...this.props.facture_user].reverse()[this.state.page].sort(function compare(b,a) {
            var string1 = a.Date_Comptable.toString();
            var string2 = b.Date_Comptable.toString();
            var dateA = new Date(parseInt(string1.substring(0,4)), parseInt(string1.substring(4,6)),parseInt(string1.substring(6,8)));
            var dateB = new Date(parseInt(string2.substring(0,4)), parseInt(string2.substring(4,6)),parseInt(string2.substring(6,8)));
          return dateA - dateB;
          }).map((facture,index) => {
            const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Num_Facture, Montant_Facture, Date_Comptable, Date_Echeance} = facture;
            var date_eche = new Date(Date_Echeance.substring(0,4),Date_Echeance.substring(4,6)-1,Date_Echeance.substring(6,8));
            var diff = new Date() - date_eche;
            diff = diff/(1000 * 60 * 60 * 24);
    
            if(diff < 30 && diff > 0 ){
                return (
                  <tr key={id} >
                    <td>{Client_Groupe}</td>
                    <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                    <td>{Code_Client}</td>
                    <td style={{textAlign:"left"}}>{Num_Commande}</td>
                    <td style={{textAlign:"left"}}>{Num_Facture}</td>
                    <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                    <td>{this.formatDate(Date_Comptable)}</td>
                    <td  title="date d'écheance dépassée de moins de 30 jours" style={{color:"orange"}}>{this.formatDate(Date_Echeance)}</td>
                  </tr>
                )
              
    
            }
            if(diff >= 30 && diff < 60){
                return (
                  <tr key={id} >
                    <td>{Client_Groupe}</td>
                    <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                    <td>{Code_Client}</td>
                    <td style={{textAlign:"left"}}>{Num_Commande}</td>
                    <td style={{textAlign:"left"}}>{Num_Facture}</td>
                    <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                    <td>{this.formatDate(Date_Comptable)}</td>
                    <td title="date d'écheance dépassée de plus de 30 jours" style={{color:'rgb(242,12,173)'}}>{this.formatDate(Date_Echeance)}</td>
                  </tr>
                )
              
    
            }
            if(diff >= 60 ){
                return (
                  <tr key={id} >
                    <td>{Client_Groupe}</td>
                    <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                    <td>{Code_Client}</td>
                    <td style={{textAlign:"left"}}>{Num_Commande}</td>
                    <td style={{textAlign:"left"}}>{Num_Facture}</td>
                    <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                    <td>{this.formatDate(Date_Comptable)}</td>
                    <td title="date d'écheance dépassée de plus de 60 jours" style={{color:"red"}}>{this.formatDate(Date_Echeance)}</td>
                  </tr>
                )
              
    
            }
            else{
                return (
                  <tr key={id} >
                    <td>{Client_Groupe}</td>
                    <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                    <td>{Code_Client}</td>
                    <td style={{textAlign:"left"}}>{Num_Commande}</td>
                    <td style={{textAlign:"left"}}>{Num_Facture}</td>
                    <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                    <td>{this.formatDate(Date_Comptable)}</td>
                    <td>{this.formatDate(Date_Echeance)}</td>
                  </tr>
                )
              
    
            }
            
        });
        }
        else{
          return this.props.facture_user[this.state.page].map((facture,index) => {
            const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Num_Facture, Montant_Facture, Date_Comptable, Date_Echeance} = facture;
            var date_eche = new Date(Date_Echeance.substring(0,4),Date_Echeance.substring(4,6)-1,Date_Echeance.substring(6,8));
            var diff = new Date() - date_eche;
            diff = diff/(1000 * 60 * 60 * 24);
    
            if(diff < 30 && diff > 0 ){
                return (
                  <tr key={id} >
                    <td>{Client_Groupe}</td>
                    <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                    <td>{Code_Client}</td>
                    <td style={{textAlign:"left"}}>{Num_Commande}</td>
                    <td style={{textAlign:"left"}}>{Num_Facture}</td>
                    <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                    <td>{this.formatDate(Date_Comptable)}</td>
                    <td  title="date d'écheance dépassée de moins de 30 jours" style={{color:"orange"}}>{this.formatDate(Date_Echeance)}</td>
                  </tr>
                )
              
    
            }
            if(diff >= 30 && diff < 60){
                return (
                  <tr key={id} >
                    <td>{Client_Groupe}</td>
                    <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                    <td>{Code_Client}</td>
                    <td style={{textAlign:"left"}}>{Num_Commande}</td>
                    <td style={{textAlign:"left"}}>{Num_Facture}</td>
                    <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                    <td>{this.formatDate(Date_Comptable)}</td>
                    <td title="date d'écheance dépassée de plus de 30 jours" style={{color:'rgb(242,12,173)'}}>{this.formatDate(Date_Echeance)}</td>
                  </tr>
                )
              
    
            }
            if(diff >= 60 ){
                return (
                  <tr key={id} >
                    <td>{Client_Groupe}</td>
                    <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                    <td>{Code_Client}</td>
                    <td style={{textAlign:"left"}}>{Num_Commande}</td>
                    <td style={{textAlign:"left"}}>{Num_Facture}</td>
                    <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                    <td>{this.formatDate(Date_Comptable)}</td>
                    <td title="date d'écheance dépassée de plus de 60 jours" style={{color:"red"}}>{this.formatDate(Date_Echeance)}</td>
                  </tr>
                )
              
    
            }
            else{
                return (
                  <tr key={id} >
                    <td>{Client_Groupe}</td>
                    <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                    <td>{Code_Client}</td>
                    <td style={{textAlign:"left"}}>{Num_Commande}</td>
                    <td style={{textAlign:"left"}}>{Num_Facture}</td>
                    <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                    <td>{this.formatDate(Date_Comptable)}</td>
                    <td>{this.formatDate(Date_Echeance)}</td>
                  </tr>
                )
              
    
            }
            
        });
        }
        
      }
    }
    else{
      return null;
    }
    
    
  }

  handleC(data){
    this.props.changeCT(true);
    this.props.changeDT(false);
    this.props.changeTitle( 'Commandes' );
    this.props.changeSuiviP( <Commande  key={"commande"} dataD={data} joinD="true"/> );
    this.props.changeStyleProfil({display:"none"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeBoolP(false);
    this.props.changeColorC(false);
    this.props.changeColorD(true);
  }

  renderTable2(){
    var nb = this.state.nb_devis;
    var nb2 = this.state.deb;
    let test = false;
    let mapping = [];
    let i = 0;
    let style_tr = {backgroundColor:"lightgreen"};
    this.props.facture_user.forEach((facture,index) => {
      facture.forEach((facture,index) => {
      const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Num_Facture, Montant_Facture, Date_Comptable, Date_Echeance} = facture;
      if((Client_Groupe.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Libelle_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Code_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Commande.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Facture.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1  || this.formatDate(Date_Comptable).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1  || this.formatDate(Date_Echeance).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1) && id)
      {
        mapping[i] = facture;
        i++;
      }
    });
  });
    while((nb-mapping.length)>12){
      nb-=12;
      nb2-=12;
    }
    return mapping.map((facture,index) => {
      const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Num_Facture, Montant_Facture, Date_Comptable, Date_Echeance} = facture;
      if(Client_Groupe.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Libelle_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Code_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Commande.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Facture.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Montant_Facture.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || this.formatDate(Date_Comptable).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1  || this.formatDate(Date_Echeance).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1)
      {
        var date_eche = new Date(Date_Echeance.substring(0,4),Date_Echeance.substring(4,6)-1,Date_Echeance.substring(6,8));
        var diff = new Date() - date_eche;
        diff = diff/(1000 * 60 * 60 * 24);

        if(diff < 30 && diff > 0 ){
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td  title="date d'écheance dépassée de moins de 30 jours" style={{color:"orange"}}>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }

        }
        if(diff >= 30 && diff < 60){
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td title="date d'écheance dépassée de plus de 30 jours" style={{color:'rgb(242,12,173)'}}>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }

        }
        if(diff >= 60 ){
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td title="date d'écheance dépassée de plus de 60 jours" style={{color:"red"}}>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }

        }
        else{
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }

        }
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }
      }
      return null;
    });
    
  }

  handleSuppr(event){

    this.setState({find:''});
   
  }
 
 handlePageS(){
    if(this.state.nb_devis < this.props.facture_user.length*12){
      console.log("suivant");
      let nb_deb = this.state.deb;
      let nb_fin = this.state.nb_devis;
      this.setState({deb:nb_deb+12});
      this.setState({nb_devis:nb_fin+12});
    }

    if(this.state.page < this.props.facture_user.length-1){
      this.setState({page:this.state.page+1});
    }
    
  }

  handlePageP(){

    if(this.state.deb >= 12){
      let nb_deb = this.state.deb;
      let nb_fin = this.state.nb_devis;
      this.setState({deb:nb_deb-12});
      this.setState({nb_devis:nb_fin-12});
    }

    if(this.state.page > 0){
      this.setState({page:this.state.page-1});
    }
   
  }

  handlePageS2(){
      this.setState({deb:this.props.facture_user.length*12-12});
      this.setState({nb_devis:this.props.facture_user.length*12});   
      this.setState({page:this.props.facture_user.length-1});
  }

  handlePageP2(){
      this.setState({deb:0});
      this.setState({nb_devis:12});
      if(this.state.page !==0 ){
        this.setState({page:0});
      }
   
  }

  handleTri(){
    this.setState({page:0});
    this.setState({is_reverse:!this.state.is_reverse, search:false,find:''});
    

    }

  render() {
    
    return (
    <div className="devis">
    <input
        id="search"
        type="text"
        placeholder="Recherche"
        name="find"
        value={this.state.find}
        onChange={this.onChange}
      /> <table id = "t2" ><thead>
          <tr>
            <th>NON &Eacute;CHUES</th> 
            <th>&Eacute;CHUES &le; 30 JOURS</th> 
            <th>&Eacute;CHUES &gt; 30 JOURS</th> 
            <th>&Eacute;CHUES &gt; 60 JOURS</th> 
            <th>TOTAL &#192; PAYER</th> 
            <th>GRAND TOTAL</th>
          </tr>
        </thead>
        <tbody>
        <tr >
          <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.state.data2)}</td>
          <td title="date d'écheance dépassée de moins de 30 jours" style={Object.assign({}, {color:'orange'},{textAlign:"right"})}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.state.data3)}</td>
          <td title="date d'écheance dépassée de plus de 30 jours" style={Object.assign({}, {color:'rgb(242,12,173)'},{textAlign:"right"})}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.state.data4)}</td>
          <td title="date d'écheance dépassée de plus de 60 jours" style={Object.assign({}, {color:'red'},{textAlign:"right"})}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.state.data5)}</td>
          <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.state.data6)}</td>
          <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.state.data7)}</td>
          </tr>
        </tbody>
     
        </table> {this.state.find.localeCompare("")==0?null:<img id="croix_cmd4" onClick={(event) => this.handleSuppr(event)} src={close} alt="croix"/>}
      <table id = "t1" >
        <thead>
          <tr>
            <th>GROUPE</th>
            <th>LIBELL&Eacute; CLIENT</th> 
            <th>CODE CLIENT</th>
            <th>N° COMMANDE</th>
            <th>N° FACTURE</th>
            <th>MONTANT TTC</th>
            <th onClick={(event) => this.handleTri(event)} style={{cursor:"pointer"}}><span title="Tri par date">DATE FACTURE<img  id="fleche1" src={fleche} alt="fleche"/></span></th>
            <th>DATE &Eacute;CHEANCE</th>
          </tr>
        </thead>
        <tbody>
            {this.renderTable(this.state.page)}
        </tbody>
      </table>
      <div id="page_d">
        <img id="pagePD" onClick={(event) => this.handlePageP2(event)} src={back2} alt="Logo back"/>
        <img id="pagePD22" onClick={(event) => this.handlePageP(event)} src={back} alt="Logo back"/>
        <img id="pageSD22" onClick={(event) => this.handlePageS(event)} src={next} alt="Logo next"/>
        <img id="pageSD" onClick={(event) => this.handlePageS2(event)} src={next2} alt="Logo next"/>
      </div>
    </div>
      );
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Facture);
