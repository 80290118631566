import React, { Component } from 'react';
import '../css/Commande.css';
import Detail from './Detail'
import { connect } from "react-redux";
import back from '../img/back.svg';
import next from '../img/next.svg';
import back2 from '../img/backback.svg';
import next2 from '../img/nextnext.svg';
import close from '../img/close.png';
import charging from "../img/charging.gif";
import logo_oeil from '../img/eye_black.png';
import { prestaTele, detail } from '../api/UserFunctions';
import Chargement from './Chargement';
import fleche from '../img/fleche.png';
import { changePage, changeIsReverse, changeDeb, changeNbDevis, changeFind, changeStyleRetour,changeSuiviP, changeTitle, changeDetail,  changeStyleProfil, changeColorC, changeColorD, changeBoolP, changeStyleD, changeStyleC, changeCT, changeDT} from "../actions/index";

const mapStateToProps = state => {
  return { page:state.page, is_reverse:state.is_reverse, commande_user:state.commande_user, profile_user: state.profile_user, presta_user: state.presta_user, find:state.find, deb:state.deb, nb_devis:state.nb_devis };
};


function mapDispatchToProps(dispatch) {
  return {
    changeTitle: title_header => dispatch(changeTitle(title_header)),
    changeSuiviP: suivi_page => dispatch(changeSuiviP(suivi_page)),
    changeStyleProfil: style_profil => dispatch(changeStyleProfil(style_profil)),
    changeStyleD: style_d => dispatch(changeStyleD(style_d)),
    changeStyleC: style_c => dispatch(changeStyleC(style_c)),
    changeDT: dt => dispatch(changeDT(dt)),
    changeCT: ct => dispatch(changeCT(ct)),
    changeDetail: detail_cmd => dispatch(changeDetail(detail_cmd)),
    changeBoolP: boolp => dispatch(changeBoolP(boolp)),
    changeColorD: colord => dispatch(changeColorD(colord)),
    changeColorC: colorc => dispatch(changeColorC(colorc)),
    changeStyleRetour: style_retour => dispatch(changeStyleRetour(style_retour)),
    changeDeb: deb => dispatch(changeDeb(deb)),
    changeNbDevis: nb_devis => dispatch(changeNbDevis(nb_devis)),
    changePage: page => dispatch(changePage(page)),
    changeIsReverse: is_reverse => dispatch(changeIsReverse(is_reverse)),
    changeFind: find => dispatch(changeFind(find))
  };
}

class Commande extends Component {

  constructor(props){
    super(props);
    this.state = {
      deb: 0,
      nb_devis:15,
      search:false,
      find:'',
      setPresta:'',
      stylePresta1:{display:"none"},
      stylePresta2:{display:"none"},
      stylePresta3:{display:"none"},
      u:0,
      DateD:'',
      DateF:'',
      descr:'',
      ref:'',
      testVoir:false,
      styleVoir:{cursor:"hand"},
      page:this.props.page,
      is_reverse:false
    }
    this.handlePageS = this.handlePageS.bind(this);
    this.handlePageP = this.handlePageP.bind(this);
    this.handlePageS2 = this.handlePageS2.bind(this);
    this.handlePageP2 = this.handlePageP2.bind(this);
    this.onChange = this.onChange.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.handleCroix1 = this.handleCroix1.bind(this);
    this.handleCroix2 = this.handleCroix2.bind(this);
    this.handleDetails = this.handleDetails.bind(this);
    this.handleDetail = this.handleDetail.bind(this);
    this.handlePV = this.handlePV.bind(this);
    this.handleSuppr = this.handleSuppr.bind(this);
    this.renderTablePresta = this.renderTablePresta.bind(this);
    this.renderTablePresta2 = this.renderTablePresta2.bind(this);

  }

  handleCroix1(event){
    this.setState({stylePresta1:{display:"none"}});
    this.setState({stylePresta3:{display:"none"}});
  }

  handleCroix2(event){
    this.setState({stylePresta3:{display:"none"}});
  }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
    this.setState({search:true});
  }

  /*componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page || this.props.is_reverse !== prevProps.is_reverse) {
      this.setState({page:this.props.page});
      this.setState({is_reverse:this.props.is_reverse});
    }
  }*/

  formatDate(date){
    let year = date.substring(0,4);
    let month = date.substring(4,6);
    let day = date.substring(6,8);

    return day+"/"+month+"/"+year;
  }

  handleDetail(data){
    detail(data.num).then(res => { 
      let inBundle = false;
      let allDatas = [];
      let getHTPrice = 0;
      let storeDetail = {};
      res.forEach((detail,index) => {
        if(detail.Bundle === 6){
          console.log("inBundle");
          if(inBundle){
            inBundle = false;
            storeDetail.HT = getHTPrice;
            allDatas.push(storeDetail);
            getHTPrice = 0;
          }
          storeDetail = detail;
          storeDetail.HT = 0;
          
        }
        if(detail.Bundle === 7){
          inBundle = true;
          getHTPrice+= detail.HT*(detail.Commandee/storeDetail.Commandee);
        }
        if(detail.Bundle === 1){
          if(inBundle){
            inBundle = false;
            storeDetail.HT = getHTPrice;
            allDatas.push(storeDetail);
            getHTPrice = 0;
          }
          allDatas.push(detail);
        }
       
      });
      if(inBundle){
        storeDetail.HT = getHTPrice;
        allDatas.push(storeDetail);
        getHTPrice = 0;
      }
      this.props.changeDetail(allDatas);
    });
    

    this.props.changeStyleRetour({display:"inline-block"});
    this.props.changeCT(true);
    this.props.changeDT(false);
    this.props.changeDeb(this.state.deb);
    this.props.changeNbDevis(this.state.nb_devis);
    this.props.changeFind(this.state.find);
    this.props.changeTitle( 'Détail' );
    this.props.changeSuiviP( <Detail  key={"detail"} dataD={data} joinD="true"/> );
    this.props.changeStyleProfil({display:"none"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeBoolP(false);
    this.props.changeColorC(false);
    this.props.changeColorD(true);
  }

  componentDidMount(){
    if(this.props.deb !== -5  && this.props.nb_devis !== -5 && this.props.find !== -5 ){
      console.log("test reverse 1 "+this.props.is_reverse);
      this.setState({nb_devis:this.props.nb_devis});
      this.setState({deb:this.props.deb});
      this.setState({find:this.props.find});
    }
  }

  renderTable(page, reverse){
   if(this.props.commande_user.length > 0){
    if(this.state.search || typeof this.props.joinD !== 'undefined' || typeof this.props.dataD !== 'undefined'){
      return this.renderTable2();          
    }
    
    else if(typeof this.props.commande_user != "undefined"){
      console.log("test reverse "+reverse+" "+this.state.page);
      if(reverse){
        return [...this.props.commande_user].reverse()[this.state.page].sort(function compare(b,a) {
          var string1 = a.Date_Saisie_Commande.toString();
          var string2 = b.Date_Saisie_Commande.toString();
          var dateA = new Date(parseInt(string1.substring(0,4)), parseInt(string1.substring(4,6)),parseInt(string1.substring(6,8)));
          var dateB = new Date(parseInt(string2.substring(0,4)), parseInt(string2.substring(4,6)),parseInt(string2.substring(6,8)));
        return dateA - dateB;
        }).map((commande,index) => {
          let testPresta="Aucune";
          let stylePresta = {color:'black'};
          let styleCursor = {cursor:"hand"};
          const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Ref_Client, Date_Saisie_Commande, Num_Devis, Statut_Commande, HT, TTC} = commande;
  
          this.props.presta_user.forEach((presta,index) => {
            if(Num_Commande.localeCompare(presta["No_de_commande"])===0){
              testPresta="Oui";
              stylePresta = {color:"green"};
              styleCursor = {cursor:"pointer"};
            }
          });
          // <td id='presta' onClick={() => this.handlePresta(Num_Commande, testPresta)} style={Object.assign({}, {width:"9vw"},stylePresta, styleCursor)}>{testPresta}{testPresta.localeCompare("Oui")===0?<img id="eye_c" src={logo_oeil} alt="oeil"/>:null}</td>
          
            
            //console.log("test reverse 3 "+Date_Saisie_Commande);
            if(this.props.profile_user.user_detail){
              const userData = {
                groupe:Client_Groupe,
                libelle:Libelle_Client,
                code:Code_Client,
                num:Num_Commande,
                ref:Ref_Client,
                date:this.formatDate(Date_Saisie_Commande),
                numd:Num_Devis,
                statut:Statut_Commande,
                ht:new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(HT),
                ttc:new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(TTC)
              }
              return (
                <tr className="test" key={id} onClick={() => this.handleDetail(userData)}>
                  <td>{Client_Groupe}</td>
                  <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                  <td>{Code_Client}</td>
                  <td>{Num_Commande}</td>
                  <td>{Ref_Client}</td>
                  <td>{this.formatDate(Date_Saisie_Commande)}</td>
                  <td>{Num_Devis}</td>
                  <td>-</td>
                  <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(HT)}</td>
                  <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(TTC)}</td>
                  
                </tr>
              )  
            }
            else {
              return (
                <tr className="test2" key={id}>
                  <td>{Client_Groupe}</td>
                  <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                  <td>{Code_Client}</td>
                  <td>{Num_Commande}</td>
                  <td>{Ref_Client}</td>
                  <td>{this.formatDate(Date_Saisie_Commande)}</td>
                  <td>{Num_Devis}</td>
                  <td>-</td>
                  <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(HT)}</td>
                  <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(TTC)}</td>
                  
                </tr>
              )
            }
           
        });
      }
      else{
        console.log("not good");
        return this.props.commande_user[this.state.page].map((commande,index) => {
          let testPresta="Aucune";
          let stylePresta = {color:'black'};
          let styleCursor = {cursor:"hand"};
          const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Ref_Client, Date_Saisie_Commande, Num_Devis, Statut_Commande, HT, TTC} = commande;
  
          this.props.presta_user.forEach((presta,index) => {
            if(Num_Commande.localeCompare(presta["No_de_commande"])===0){
              testPresta="Oui";
              stylePresta = {color:"green"};
              styleCursor = {cursor:"pointer"};
            }
          });
          // <td id='presta' onClick={() => this.handlePresta(Num_Commande, testPresta)} style={Object.assign({}, {width:"9vw"},stylePresta, styleCursor)}>{testPresta}{testPresta.localeCompare("Oui")===0?<img id="eye_c" src={logo_oeil} alt="oeil"/>:null}</td>
          
          if(this.props.profile_user.user_detail){
            const userData = {
              groupe:Client_Groupe,
              libelle:Libelle_Client,
              code:Code_Client,
              num:Num_Commande,
              ref:Ref_Client,
              date:this.formatDate(Date_Saisie_Commande),
              numd:Num_Devis,
              statut:Statut_Commande,
              ht:new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(HT),
              ttc:new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(TTC)
            }
            return (
              <tr className="test" key={id} onClick={() => this.handleDetail(userData)}>
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td>{Num_Commande}</td>
                <td>{Ref_Client}</td>
                <td>{this.formatDate(Date_Saisie_Commande)}</td>
                <td>{Num_Devis}</td>
                <td>-</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(HT)}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(TTC)}</td>
                
              </tr>
            )  
          }
          else {
            return (
              <tr className="test2" key={id}>
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td>{Num_Commande}</td>
                <td>{Ref_Client}</td>
                <td>{this.formatDate(Date_Saisie_Commande)}</td>
                <td>{Num_Devis}</td>
                <td>-</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(HT)}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(TTC)}</td>
                
              </tr>
            )  
          }
        });
      }
      
    }
   }
    else{
      return null;
    }
  }

  renderTablePresta(){
      let u = 0;
      return this.props.presta_user.map((presta,index) => {
          //this.handleDetails(presta['No_ticket'])
          //this.handlePV(presta['No_ticket'])
            let html = presta['Description_ticket'];
            html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
            html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
            html = html.replace(/<\/div>/ig, '\n');
            html = html.replace(/<\/li>/ig, '\n');
            html = html.replace(/<li>/ig, '  *  ');
            html = html.replace(/<\/ul>/ig, '\n');
            html = html.replace(/<\/p>/ig, '\n');
            html = html.replace(/<br\s*[/]?>/gi, "\n");
            html = html.replace(/<[^>]+>/ig, ''); 
            u+=1;
            let val_voir = "";
            let val_style = {};
            let url = presta["Pdf_url"];
            if(presta["No_de_commande"].localeCompare(this.state.Num)==0){
            if("".localeCompare(presta["Pdf_url"]) == 0){
              val_voir="-";
              val_style={cursor:"hand"};
            }
            else{
              url = url.substring(102, url.length);
              if(url.substring(0,2).localeCompare("PV")===0){
                val_voir="Voir";
                val_style={cursor:"pointer"};
              }
              else{
                val_voir="-";
                val_style={cursor:"hand"};
              }
              
            }
            
                return (
                <tr key={u}>
                  <td className="td_presta">{presta["No_ticket"]}</td>
                  <td className="td_presta">{html}</td>
                  <td className="td_presta">{presta["Date_debut_planifie_action"].substring(0,11)}</td>
                  <td className="td_presta">{presta["Statut"]}</td>
                  <td className="td_presta" onClick={() => this.handlePV()} style={val_style}>{val_voir}</td>
                </tr>  
              ) 
            }
            else{
              return null;
            }
             
        });
        
  }

  renderTablePresta2(){
    if(typeof this.state.doc !== "undefined"){

      let u = 0;
      let t = true;
        u+=1;

        let nomDoc = this.state.doc;
        if ("".localeCompare(nomDoc) !== 0){
          var array = nomDoc.split(",");
          return array.map((url,index) => {
            let first = url
            url = url.substring(102, url.length);
            url = url.replace(/%/ig, ' ');
            url = url.replace(/2B/ig, ' ');
            url = url.replace(/25C3/ig, ' ');
            url = url.replace(/25A9/ig, ' ');
            if(url.substring(0,2).localeCompare("PV")===0){
              t=false;
              return (
                <p key={u} className="prestaNum3" onClick={() => this.handleTele(first)}>{url.substring(0,url.length-4)}</p>  
              )  
            }
            else{
              return null;
            }
          });
          }
          else{
            return null;

        }
      
    }
      

    
    return null; 
  }

  handleTele(link){
    prestaTele(link).then(res => {
          });
  }

  handleDetails(test){
    this.setState({stylePresta2:{display:"block"}});
    this.setState({stylePresta3:{display:"none"}});
      this.props.presta_user.forEach((presta,index) => {
          if(presta['No_ticket'].localeCompare(test)===0){
            this.setState({DateD:presta['Date_debut_planifie_action']});
            let html = presta['Description_ticket'];
            html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
            html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
            html = html.replace(/<\/div>/ig, '\n');
            html = html.replace(/<\/li>/ig, '\n');
            html = html.replace(/<li>/ig, '  *  ');
            html = html.replace(/<\/ul>/ig, '\n');
            html = html.replace(/<\/p>/ig, '\n');
            html = html.replace(/<br\s*[/]?>/gi, "\n");
            html = html.replace(/<[^>]+>/ig, '');
            this.setState({descr:html});
          }
        
        });

    
  }

  handlePV(){
    this.setState({stylePresta3:{display:"block"}});
    this.setState({stylePresta2:{display:"none"}});
    this.props.presta_user.forEach((presta,index) => {
      if(this.state.Num.localeCompare(presta["No_de_commande"])==0){
        this.setState({doc:presta['Pdf_url']});
      }
    });
  }

  handlePresta(presta1, testPresta){
    let listPresta = [];
    let u = 0;
    if(testPresta.localeCompare("Oui") === 0){
      this.setState({Num:presta1})
      this.setState({stylePresta1:{display:"block"}})
    }
    
    
  }


  renderTable2(){
    var nb = this.state.nb_devis;
    var nb2 = this.state.deb;
    let mapping = [];
    let i = 0;
    if(typeof this.props.dataD !== 'undefined'){
      this.props.commande_user.forEach((commande,index) => {
        commande.forEach((commande,index) => {
        const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Ref_Client, Date_Saisie_Commande, Num_Devis, Statut_Commande, HT, TTC} = commande;
        if((Num_Devis.toUpperCase().indexOf(this.props.dataD.toUpperCase()) !== -1) && id)
        {
          mapping[i] = commande;
          i++;
        }
      });
      });
    }
    else{
      this.props.commande_user.forEach((commande,index) => {
        commande.forEach((commande,index) => {
        const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Ref_Client, Date_Saisie_Commande, Num_Devis, Statut_Commande, HT, TTC} = commande;
        //console.log("client "+Client_Groupe+" "+Libelle_Client+" "+Code_Client+" "+Num_Commande+" "+Ref_Client+" "+Date_Saisie_Commande+" "+Num_Devis+" "+Statut_Commande+" "+HT+" "+TTC)
        if((Client_Groupe.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Libelle_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Code_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Commande.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1  || this.formatDate(Date_Saisie_Commande).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Devis.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Statut_Commande.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || HT.toString().toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || TTC.toString().toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1) && id)
        {
          mapping[i] = commande;
          i++;
        }
      });
      });
    }
    while((nb-mapping.length)>15){
      nb-=15;
      nb2-=15;
    }
    return mapping.map((commande,index) => {
      const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Ref_Client, Date_Saisie_Commande, Num_Devis, Statut_Commande, HT, TTC} = commande;
      if( (Client_Groupe.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Libelle_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Code_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Commande.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1  || this.formatDate(Date_Saisie_Commande).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Devis.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Statut_Commande.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || HT.toString().toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || TTC.toString().toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1) && id)
      {
        let testPresta="Aucune";
        let stylePresta = {color:"black"};
        let styleCursor = {cursor:"hand"};
        const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Ref_Client, Date_Saisie_Commande, Num_Devis, Statut_Commande, HT, TTC} = commande;

        this.props.presta_user.forEach((presta,index) => {
          if(Num_Commande.localeCompare(presta["No_de_commande"])===0 && ("Projet".localeCompare(presta["Groupe_affectation"])===0 || "Infrastructure".localeCompare(presta["Groupe_affectation"]))){
            testPresta="Oui";
            stylePresta = {color:"green"};
            styleCursor = {cursor:"pointer"};
          }
        });
          //<td id='presta' onClick={() => this.handlePresta(Num_Commande, testPresta)} style={Object.assign({}, stylePresta, styleCursor, {width:"9vw"})}>{testPresta}{testPresta.localeCompare("Oui")===0?<img id="eye_c" src={logo_oeil} alt="oeil"/>:null}</td>
          if(index<nb && index>=nb2){  
            const userData = {
              groupe:Client_Groupe,
              libelle:Libelle_Client,
              code:Code_Client,
              num:Num_Commande,
              ref:Ref_Client,
              date:this.formatDate(Date_Saisie_Commande),
              numd:Num_Devis,
              statut:Statut_Commande,
              ht:new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(HT),
              ttc:new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(TTC)
            }   
            if(this.props.profile_user.user_detail){
              return (
                <tr className="test" key={id} onClick={() => this.handleDetail(userData)}>
                  <td>{Client_Groupe}</td>
                  <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                  <td>{Code_Client}</td>
                  <td>{Num_Commande}</td>
                  <td>{Ref_Client}</td>
                  <td>{this.formatDate(Date_Saisie_Commande)}</td>
                  <td>{Num_Devis}</td>
                  <td>-</td>
                  <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(HT)}</td>
                  <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(TTC)}</td>
                  
                </tr>
              )  
            }
            else {
              return (
                <tr className="test2" key={id}>
                  <td>{Client_Groupe}</td>
                  <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                  <td>{Code_Client}</td>
                  <td>{Num_Commande}</td>
                  <td>{Ref_Client}</td>
                  <td>{this.formatDate(Date_Saisie_Commande)}</td>
                  <td>{Num_Devis}</td>
                  <td>-</td>
                  <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(HT)}</td>
                  <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(TTC)}</td>
                  
                </tr>
              )
            }
          }
        return null;
      }
      return null;
    });
  }

  handlePageS(){
    if(this.state.nb_devis < this.props.commande_user.length*15){
      let nb_deb = this.state.deb;
      let nb_fin = this.state.nb_devis;
      this.setState({deb:nb_deb+15});
      this.setState({nb_devis:nb_fin+15});
    }

    if(this.state.page < this.props.commande_user.length-1){
      this.props.changePage(this.state.page+1);
      this.setState({page:this.state.page+1});
    }
    
  }

  handlePageP(){

    if(this.state.deb >= 15){
      let nb_deb = this.state.deb;
      let nb_fin = this.state.nb_devis;
      this.setState({deb:nb_deb-15});
      this.setState({nb_devis:nb_fin-15});
    }

    if(this.state.page > 0){
      this.props.changePage(this.state.page-1);
      this.setState({page:this.state.page-1});
    }
   
  }

  handleSuppr(event){

    this.setState({find:''});
   
  }

  handlePageS2(){
    this.setState({deb:this.props.commande_user.length*15-15});
    this.setState({nb_devis:this.props.commande_user.length*15});
    this.setState({page:this.props.commande_user.length-1});
    this.props.changePage(this.props.commande_user.length-1);
}

handlePageP2(){
    this.setState({deb:0});
    this.setState({nb_devis:15});

    if(this.state.page !==0 ){
      this.setState({page:0});
      this.props.changePage(0);
    }
 
}
  
  handleTri(){
    this.setState({page:0});
    this.props.changePage(0);
    this.props.changeIsReverse(!this.state.is_reverse);
    this.setState({is_reverse:!this.state.is_reverse, search:false,find:''});
    
    

    }
//<th>PRESTATIONS<br/>PLANIFI&Eacute;ES</th>
  render() {
    
      return (

    <div className="commande">
      <input
        id="search"
        type="text"
        placeholder="Recherche"
        name="find"
        value={this.state.find}
        onChange={this.onChange}
        onClick={(event) => this.handleSuppr(event)}
      /> {this.state.find.localeCompare("")==0?null:<img id="croix_cmd3" onClick={(event) => this.handleSuppr(event)} src={close} alt="croix"/>}

      <table >
        <thead id="table_d">
          <tr>
            <th>GROUPE</th>
            <th>LIBELL&Eacute; CLIENT</th> 
            <th>CODE<br/>CLIENT</th>
            <th>N°<br/>COMMANDE</th>
            <th>REF CDE<br/>CLIENT</th>
            <th onClick={(event) => this.handleTri(event)} style={{cursor:"pointer"}}><span title="Tri par date">DATE COMMANDE<img  id="fleche2" src={fleche} alt="fleche"/></span></th>
            <th>N°<br/>DE DEVIS</th>
            <th>STATUT DE LA<br/>COMMANDE</th>
            <th>MONTANT<br/>HT</th>
            <th>MONTANT<br/>TTC</th>
            
          </tr>
        </thead>
        <tbody>
          {this.renderTable(this.state.page,this.state.is_reverse)}
        </tbody>
      </table>
      <div id="page_c">
      <img id="pagePD" onClick={(event) => this.handlePageP2(event)} src={back2} alt="Logo back"/>
      <img id="pagePD22" onClick={(event) => this.handlePageP(event)} src={back} alt="Logo back"/>
        <img id="pageSD22" onClick={(event) => this.handlePageS(event)} src={next} alt="Logo next"/>
        <img id="pageSD" onClick={(event) => this.handlePageS2(event)} src={next2} alt="Logo next"/>
      </div>

      <div id="modalPresta1" style={this.state.stylePresta1} >
            <div className="modal-content1" >
              <p id="forget_title1">Actions prévues<img id="croix_cmd" onClick={(event) => this.handleCroix1(event)} src={close} alt="croix"/></p>
              <div id="detail_content">
                <table >
                  <thead>
                    <tr>
                      <th className="th_cmd">N° de ticket</th>
                      <th className="th_cmd">Déscriptif</th> 
                      <th className="th_cmd">Date d'intervention</th>
                      <th className="th_cmd">Statut</th>
                      <th className="th_cmd">Pièce jointe</th>
                    </tr>
                  </thead>
                  <tbody id="table_presta">
                    {this.renderTablePresta()}
                  </tbody>
                </table>
              </div>
              
            </div>      
      </div>
      <div id="modalPresta3" style={this.state.stylePresta3} >
              <p id="forget_title3">Procès-verbaux<img id="croix_cmd2" onClick={(event) => this.handleCroix2(event)} src={close} alt="croix"/></p>
              {this.renderTablePresta2()}   
      </div>
    </div>
    );
    
    }

  }
export default connect(mapStateToProps, mapDispatchToProps)(Commande);
