import axios from 'axios';


export const prestaTele = (link) => {
	let nomDoc = link;
    nomDoc = nomDoc.substring(102, nomDoc.length);
    nomDoc = nomDoc.replace(/%/ig, ' ');
    nomDoc = nomDoc.replace(/2B/ig, ' ');
    nomDoc = nomDoc.replace(/25C3/ig, ' ');
    nomDoc = nomDoc.replace(/25A9/ig, ' ');
	
	return axios({
         method: 'get',
         url:"https://cors-anywhere.herokuapp.com/"+link,
         auth: {
            username: process.env.REACT_APP_ID_PRESTA,
	    	password: process.env.REACT_APP_MDP_PRESTA
          },
      	responseType: 'blob'})
	    .then(({ data }) => {

        const downloadUrl = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.setAttribute('download', nomDoc); //any other extension

        document.body.appendChild(link);

        link.click();

        link.remove();

      })
	    .catch(err => {
	      	console.log(err);
	      	
	    })
}


export const login = user => {
	
	return axios({
          method: 'post',
          url: process.env.REACT_APP_API+'/users/login',
          data: {
            email: user.email,
	      	password: user.password
          },
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      	localStorage.setItem('usertoken', response.data);
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	if(typeof err.response.status != undefined){
	      		if(err.response.status === 429){
	      			user.ok = 429;
	      	}
	      	if(err.response.status === 400){
	      			user.ok = 400;
	      	}
	      	if(err.response.status === 401){
	      			user.ok = 401;
	      	}
	      	}
	      	
	    })


}

export const update_userf = user => {
	
	return axios({
          method: 'put',
          url: process.env.REACT_APP_API+'/users/update_userf',
          data: {
    		email: user.login.login,
    		password: user.password,
          },
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);	
	    })


}

export const update_user = user => {
	
	return axios({
          method: 'put',
          url: process.env.REACT_APP_API+'/users/update_user/'+user.id,
          data: {
    		login: user.login,
    		password: user.password,
    		status: user.status,
          },
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}


export const update_logo = dataImg => {

	var bodyFormData = new FormData();
	bodyFormData.set('etps', dataImg.etps);
	bodyFormData.append('file', dataImg.selectedFile); 
	
	return axios({
          method: 'put',
          url: process.env.REACT_APP_API+'/users/update_logo/'+dataImg.id,
          data:bodyFormData,
          headers: {'Content-Type': 'multipart/form-data' },
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}





export const deleteuser = user => {
	
	return axios({
          method: 'delete',
          url: process.env.REACT_APP_API+'/users/delete_user/'+user,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const deletelogo = user => {
	
	return axios({
          method: 'delete',
          url: process.env.REACT_APP_API+'/users/delete_logo/'+user,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const register = user => {
	
	return axios({
          method: 'post',
          url: process.env.REACT_APP_API+'/users/register/',
          data: {
    		login: user.login,
    		password: user.password,
			status: user.status,
          },
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}


export const sendImg = dataImg => {

	var bodyFormData = new FormData();
	bodyFormData.set('etps', dataImg.etps);
	bodyFormData.append('file', dataImg.selectedFile); 
	
	return axios({
          method: 'post',
          url: process.env.REACT_APP_API+'/users/create_img/',
          data:bodyFormData,
          headers: {'Content-Type': 'multipart/form-data' },
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {  
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}


export const profile = () => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/profile',
          headers:{
                Authorization : localStorage.getItem('usertoken')
          
         } })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}



export const presta = () => {
	
	return axios({
         method: 'get',
         url:process.env.REACT_APP_API+'/users/presta',
         auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          }})
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}


export const findallusers = () => {
	
	return axios({
         method: 'get',
         url:process.env.REACT_APP_API+'/users/findallusers',
         auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          }})
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const imgList = () => {
	
	return axios({
         method: 'get',
         url:process.env.REACT_APP_API+'/users/img',
         auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          }})
	    .then(response => {
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}


export const imgGet = name => {
	
	return axios({
         method: 'get',
         url:process.env.REACT_APP_API+'/users/display/'+name,
         auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          }})
	    .then(response => {
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}


export const commande = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/commande/client/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const detail = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/detail/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}




export const commande_groupe = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/commande/groupe/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const check = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/check/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const mail = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/mail/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}


export const commande_all = () => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/commande/',
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const devis_all = () => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/devis/',
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const facture_all = () => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/facture/',
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}



export const facture = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/facture/client/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const facture_groupe = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/facture/groupe/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const devis = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/devis/client/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const devis_groupe = num => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/devis/groupe/'+num,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })

}


export const findoneuser = id => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/findoneuser/'+id,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}

export const findalllogos = () => {
	
	return axios({
         method: 'get',
         url:process.env.REACT_APP_API+'/users/findalllogos',
         auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          }})
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}


export const findonelogo = id => {
	
	return axios({
          method: 'get',
          url: process.env.REACT_APP_API+'/users/findonelogo/'+id,
          auth: {
            username: process.env.REACT_APP_ID,
	    	password: process.env.REACT_APP_MDP
          },
        })
	    .then(response => {
	      
	      return response.data;
	    })
	    .catch(err => {
	      	console.log(err);
	      	
	    })


}