import React, { Component } from 'react';
import '../css/Devis.css';
import { connect } from "react-redux";
import back from '../img/back.svg';
import next from '../img/next.svg';
import Commande from './Commande';
import Chargement from './Chargement';
import close from '../img/close.png';
import logo_oeil from '../img/eye_black.png';
import { Chart } from "react-google-charts";
import { changeSuiviP, changeTitle, changeStyleProfil, changeColorC, changeColorD, changeBoolP, changeStyleD, changeStyleC, changeCT, changeDT} from "../actions/index";


function mapDispatchToProps(dispatch) {
    return {
      changeTitle: title_header => dispatch(changeTitle(title_header)),
      changeSuiviP: suivi_page => dispatch(changeSuiviP(suivi_page)),
      changeStyleProfil: style_profil => dispatch(changeStyleProfil(style_profil)),
      changeStyleD: style_d => dispatch(changeStyleD(style_d)),
      changeStyleC: style_c => dispatch(changeStyleC(style_c)),
      changeDT: dt => dispatch(changeDT(dt)),
      changeCT: ct => dispatch(changeCT(ct)),
      changeBoolP: boolp => dispatch(changeBoolP(boolp)),
      changeColorD: colord => dispatch(changeColorD(colord)),
      changeColorC: colorc => dispatch(changeColorC(colorc))
    };
}

const mapStateToProps = state => {
  return {commande_user:state.commande_user, devis_user:state.devis_user,tabGroupe:state.tabGroupe, profile_user: state.profile_user, tabData1: state.tabData1, tabData2: state.tabData2, tabData3: state.tabData3 };
};



class Reporting extends Component {

 constructor(props){
    super(props);
    this.state = {
      deb: 0,
      nb_devis:15,
      search:false,
      find:'',
      style_tr:{backgroundColor:"#fafafa"},
      test:false,
      data1:"TOUS",
      year:"2021",
      chargement:false,
      track1:[
            ['Mois', 'Devis', 'Devis transformés en commandes','Commandes'],
            ['Janvier', this.props.tabData1[0][0][0], 0, this.props.tabData3[0][0][0]],
            ['Février', this.props.tabData1[0][0][1], 0, this.props.tabData3[0][0][1]],
            ['Mars', this.props.tabData1[0][0][2], 0, this.props.tabData3[0][0][2]],
            ['Avril', this.props.tabData1[0][0][3], 0, this.props.tabData3[0][0][3]],
            ['Mai', this.props.tabData1[0][0][4], 0, this.props.tabData3[0][0][4]],
            ['Juin', this.props.tabData1[0][0][5], 0, this.props.tabData3[0][0][5]],
            ['Juillet', this.props.tabData1[0][0][6],0, this.props.tabData3[0][0][6]],
            ['Août', this.props.tabData1[0][0][7], 0, this.props.tabData3[0][0][7]],
            ['Septembre', this.props.tabData1[0][0][8], 0, this.props.tabData3[0][0][8]],
            ['Octobre', this.props.tabData1[0][0][9], 0, this.props.tabData3[0][0][9]],
            ['Novembre', this.props.tabData1[0][0][10], 0, this.props.tabData3[0][0][10]],
            ['Decembre', this.props.tabData1[0][0][11], 0, this.props.tabData3[0][0][11]]
          ]
    }
    this.handlePageS = this.handlePageS.bind(this);
    this.handlePageP = this.handlePageP.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.onChange3 = this.onChange3.bind(this);
    this.handleSuppr = this.handleSuppr.bind(this);
    this.renderSel = this.renderSel.bind(this);
  }


  componentDidMount(){
    this.setState({chargement:false});
            var one1y0=0;
            var two1y0=0;
            var three1y0=0;
            var four1y0=0;
            var five1y0=0;
            var six1y0=0;
            var seven1y0=0;
            var eight1y0=0;
            var nine1y0=0;
            var ten1y0=0;
            var eleven1y0=0;
            var twelve1y0=0;

            var one1y1=0;
            var two1y1=0;
            var three1y1=0;
            var four1y1=0;
            var five1y1=0;
            var six1y1=0;
            var seven1y1=0;
            var eight1y1=0;
            var nine1y1=0;
            var ten1y1=0;
            var eleven1y1=0;
            var twelve1y1=0;
    this.props.commande_user.forEach((commande,index) => {
      commande.forEach((commande,index) => {
       this.props.devis_user.forEach((devis,index) => {
        devis.forEach((devis,index) => {
                    if(commande["Num_Devis"].localeCompare(devis["Num_Devis"])===0){
                        if((devis["Date_Crea_Devis"].substring(0,4).localeCompare("2020"))===0){
                   if(this.state.data1.localeCompare("TOUS")===0){
                    if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("01"))===0){
                      one1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("02"))===0){
                      two1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("03"))===0){
                      three1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("04"))===0){
                      four1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("05"))===0){
                      five1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("06"))===0){
                      six1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("07"))===0){
                      seven1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("08"))===0){
                      eight1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("09"))===0){
                      nine1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("10"))===0){
                      ten1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("11"))===0){
                      eleven1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("12"))===0){
                      twelve1y0+=1;
                    }
                  }
                }
                else if((devis["Date_Crea_Devis"].substring(0,4).localeCompare("2021"))===0){
                  if( this.state.data1.localeCompare("TOUS")===0){
                    if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("01"))===0){
                      one1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("02"))===0){
                      two1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("03"))===0){
                      three1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("04"))===0){
                      four1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("05"))===0){
                      five1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("06"))===0){
                      six1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("07"))===0){
                      seven1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("08"))===0){
                      eight1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("09"))===0){
                      nine1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("10"))===0){
                      ten1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("11"))===0){
                      eleven1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("12"))===0){
                      twelve1y1+=1;
                    }
                  }

                }
                      
                    }
                  });
                });
                });

        });



    if(this.state.year.localeCompare("2020")===0){
      this.setState({ track1: [
            ['Mois', 'Devis', 'Devis transformés en commandes','Commandes'],
            ['Janvier', this.props.tabData1[0][0][0], one1y0, this.props.tabData3[0][0][0]],
            ['Février', this.props.tabData1[0][0][1], two1y0, this.props.tabData3[0][0][1]],
            ['Mars', this.props.tabData1[0][0][2], three1y0, this.props.tabData3[0][0][2]],
            ['Avril', this.props.tabData1[0][0][3], four1y0, this.props.tabData3[0][0][3]],
            ['Mai', this.props.tabData1[0][0][4], five1y0, this.props.tabData3[0][0][4]],
            ['Juin', this.props.tabData1[0][0][5], six1y0, this.props.tabData3[0][0][5]],
            ['Juillet', this.props.tabData1[0][0][6], seven1y0, this.props.tabData3[0][0][6]],
            ['Août', this.props.tabData1[0][0][7], eight1y0, this.props.tabData3[0][0][7]],
            ['Septembre', this.props.tabData1[0][0][8], nine1y0, this.props.tabData3[0][0][8]],
            ['Octobre',this.props.tabData1[0][0][9], ten1y0, this.props.tabData3[0][0][9]],
            ['Novembre',this.props.tabData1[0][0][10], eleven1y0, this.props.tabData3[0][0][10]],
            ['Decembre', this.props.tabData1[0][0][11], twelve1y0, this.props.tabData3[0][0][11]]
          ] });
    }

    this.setState({chargement:true});

      
    }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({search:false});
        var one1y0 = 0;
        var two1y0 = 0;
        var three1y0 = 0;
        var four1y0 = 0;
        var five1y0 = 0;
        var six1y0 = 0;
        var seven1y0 = 0;
        var eight1y0 = 0;
        var nine1y0 = 0;
        var ten1y0 = 0;
        var eleven1y0 = 0;
        var twelve1y0 = 0;

        var one1y1 = 0;
        var two1y1 = 0;
        var three1y1 = 0;
        var four1y1 = 0;
        var five1y1 = 0;
        var six1y1 = 0;
        var seven1y1 = 0;
        var eight1y1 = 0;
        var nine1y1 = 0;
        var ten1y1 = 0;
        var eleven1y1 = 0;
        var twelve1y1 = 0;
        this.props.commande_user.forEach((commande,index) => {
          commande.forEach((commande,index) => {
           this.props.devis_user.forEach((devis,index) => {
            devis.forEach((devis,index) => {
                if (commande["Num_Devis"].localeCompare(devis["Num_Devis"]) === 0) {
                    if ((devis["Date_Crea_Devis"].substring(0, 4).localeCompare("2020")) === 0) {
                        if (e.target.value.localeCompare(devis['Libelle_Client']) === 0 || e.target.value.localeCompare("TOUS") === 0) {
                            if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("01")) === 0) {
                                one1y0 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("02")) === 0) {
                                two1y0 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("03")) === 0) {
                                three1y0 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("04")) === 0) {
                                four1y0 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("05")) === 0) {
                                five1y0 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("06")) === 0) {
                                six1y0 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("07")) === 0) {
                                seven1y0 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("08")) === 0) {
                                eight1y0 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("09")) === 0) {
                                nine1y0 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("10")) === 0) {
                                ten1y0 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("11")) === 0) {
                                eleven1y0 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("12")) === 0) {
                                twelve1y0 += 1;
                            }
                        }
                    } else if ((devis["Date_Crea_Devis"].substring(0, 4).localeCompare("2021")) === 0) {
                        if (e.target.value.localeCompare(devis['Libelle_Client']) === 0 || e.target.value.localeCompare("TOUS") === 0) {
                            if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("01")) === 0) {
                                one1y1 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("02")) === 0) {
                                two1y1 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("03")) === 0) {
                                three1y1 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("04")) === 0) {
                                four1y1 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("05")) === 0) {
                                five1y1 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("06")) === 0) {
                                six1y1 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("07")) === 0) {
                                seven1y1 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("08")) === 0) {
                                eight1y1 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("09")) === 0) {
                                nine1y1 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("10")) === 0) {
                                ten1y1 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("11")) === 0) {
                                eleven1y1 += 1;
                            } else if ((devis["Date_Crea_Devis"].substring(4, 6).localeCompare("12")) === 0) {
                                twelve1y1 += 1;
                            }
                        }

                    }

                }
            });
          });
        });
        });
  }

  onChange2(e) {
    this.setState({chargement:false});
    this.setState({ data1: e.target.value });
            var one1y0=0;
            var two1y0=0;
            var three1y0=0;
            var four1y0=0;
            var five1y0=0;
            var six1y0=0;
            var seven1y0=0;
            var eight1y0=0;
            var nine1y0=0;
            var ten1y0=0;
            var eleven1y0=0;
            var twelve1y0=0;

            var one1y1=0;
            var two1y1=0;
            var three1y1=0;
            var four1y1=0;
            var five1y1=0;
            var six1y1=0;
            var seven1y1=0;
            var eight1y1=0;
            var nine1y1=0;
            var ten1y1=0;
            var eleven1y1=0;
            var twelve1y1=0;
            this.props.commande_user.forEach((commande,index) => {
              commande.forEach((commande,index) => {
               this.props.devis_user.forEach((devis,index) => {
                devis.forEach((devis,index) => {
                    if(commande["Num_Devis"].localeCompare(devis["Num_Devis"])===0){
console.log("ok1");
                        if((devis["Date_Crea_Devis"].substring(0,4).localeCompare("2020"))===0){
                   if(e.target.value.localeCompare(devis['Libelle_Client'])===0 || e.target.value.localeCompare("TOUS")===0){
                    if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("01"))===0){
                      one1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("02"))===0){
                      two1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("03"))===0){
                      three1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("04"))===0){
                      four1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("05"))===0){
                      five1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("06"))===0){
                      six1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("07"))===0){
                      seven1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("08"))===0){
                      eight1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("09"))===0){
                      nine1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("10"))===0){
                      ten1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("11"))===0){
                      eleven1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("12"))===0){
                      twelve1y0+=1;
                    }
                  }
                }
                else if((devis["Date_Crea_Devis"].substring(0,4).localeCompare("2021"))===0){
                  console.log("ok2");
                  if(e.target.value.localeCompare(devis['Libelle_Client'])===0 || e.target.value.localeCompare("TOUS")===0){
                    if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("01"))===0){
                      one1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("02"))===0){
                      two1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("03"))===0){
                      three1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("04"))===0){
                      four1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("05"))===0){
                      five1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("06"))===0){
                      six1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("07"))===0){
                      seven1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("08"))===0){
                      eight1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("09"))===0){
                      nine1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("10"))===0){
                      ten1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("11"))===0){
                      eleven1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("12"))===0){
                      twelve1y1+=1;
                    }
                  }

                }
                      
                    }
                  });
                });
              });
        });



    if(this.state.year.localeCompare("2020")===0){
      this.setState({ track1: [
            ['Mois', 'Devis', 'Devis transformés en commandes','Commandes'],
            ['Janvier', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][0][0], one1y0, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][0][0]],
            ['Février', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][0][1], two1y0, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][0][1]],
            ['Mars', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][0][2], three1y0, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][0][2]],
            ['Avril', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][0][3], four1y0, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][0][3]],
            ['Mai', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][0][4], five1y0, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][0][4]],
            ['Juin', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][0][5], six1y0, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][0][5]],
            ['Juillet', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][0][6], seven1y0, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][0][6]],
            ['Août', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][0][7], eight1y0, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][0][7]],
            ['Septembre', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][0][8], nine1y0, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][0][8]],
            ['Octobre', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][0][9], ten1y0, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][0][9]],
            ['Novembre', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][0][10], eleven1y0, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][0][10]],
            ['Decembre', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][0][11], twelve1y0, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][0][11]]
          ] });
    }
    else if(this.state.year.localeCompare("2021")===0){
      this.setState({ track1: [
            ['Mois', 'Devis', 'Devis transformés en commandes','Commandes'],
            ['Janvier', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][1][0],one1y1, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][1][0]],
            ['Février', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][1][1],two1y1, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][1][1]],
            ['Mars', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][1][2], three1y1, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][1][2]],
            ['Avril', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][1][3], four1y1, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][1][3]],
            ['Mai', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][1][4], five1y1, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][1][4]],
            ['Juin', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][1][5], six1y1, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][1][5]],
            ['Juillet', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][1][6], seven1y1, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][1][6]],
            ['Août', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][1][7], eight1y1, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][1][7]],
            ['Septembre', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][1][8], nine1y1, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][1][8]],
            ['Octobre', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][1][9], ten1y1, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][1][9]],
            ['Novembre', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][1][10], eleven1y1, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][1][10]],
            ['Decembre', this.props.tabData1[this.props.tabGroupe.indexOf(e.target.value)][1][11], twelve1y1, this.props.tabData3[this.props.tabGroupe.indexOf(e.target.value)][1][11]]
          ] });
    }

    this.setState({chargement:true});
    
  }


  onChange3(e) {
    this.setState({chargement:false});
    this.setState({ year: e.target.value });
            var one1y0=0;
            var two1y0=0;
            var three1y0=0;
            var four1y0=0;
            var five1y0=0;
            var six1y0=0;
            var seven1y0=0;
            var eight1y0=0;
            var nine1y0=0;
            var ten1y0=0;
            var eleven1y0=0;
            var twelve1y0=0;

            var one1y1=0;
            var two1y1=0;
            var three1y1=0;
            var four1y1=0;
            var five1y1=0;
            var six1y1=0;
            var seven1y1=0;
            var eight1y1=0;
            var nine1y1=0;
            var ten1y1=0;
            var eleven1y1=0;
            var twelve1y1=0;
            this.props.commande_user.forEach((commande,index) => {
              commande.forEach((commande,index) => {
               this.props.devis_user.forEach((devis,index) => {
                devis.forEach((devis,index) => {
                    if(commande["Num_Devis"].localeCompare(devis["Num_Devis"])===0){
                        if((devis["Date_Crea_Devis"].substring(0,4).localeCompare("2020"))===0){
                   if(this.state.data1.localeCompare(devis['Libelle_Client'])===0 || this.state.data1.localeCompare("TOUS")===0){
                    if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("01"))===0){
                      one1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("02"))===0){
                      two1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("03"))===0){
                      three1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("04"))===0){
                      four1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("05"))===0){
                      five1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("06"))===0){
                      six1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("07"))===0){
                      seven1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("08"))===0){
                      eight1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("09"))===0){
                      nine1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("10"))===0){
                      ten1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("11"))===0){
                      eleven1y0+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("12"))===0){
                      twelve1y0+=1;
                    }
                  }
                }
                else if((devis["Date_Crea_Devis"].substring(0,4).localeCompare("2021"))===0){
                  if(this.state.data1.localeCompare(devis['Libelle_Client'])===0 || this.state.data1.localeCompare("TOUS")===0){
                    if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("01"))===0){
                      one1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("02"))===0){
                      two1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("03"))===0){
                      three1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("04"))===0){
                      four1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("05"))===0){
                      five1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("06"))===0){
                      six1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("07"))===0){
                      seven1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("08"))===0){
                      eight1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("09"))===0){
                      nine1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("10"))===0){
                      ten1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("11"))===0){
                      eleven1y1+=1;
                    }
                    else if((devis["Date_Crea_Devis"].substring(4,6).localeCompare("12"))===0){
                      twelve1y1+=1;
                    }
                  }

                }
                      
                    }
                  });
                });
              });

        });



    if(e.target.value.localeCompare("2020")===0){
      this.setState({ track1: [
            ['Mois', 'Devis', 'Devis transformés en commandes','Commandes'],
            ['Janvier', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][0][0], one1y0, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][0][0]],
            ['Février', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][0][1], two1y0, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][0][1]],
            ['Mars', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][0][2], three1y0, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][0][2]],
            ['Avril', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][0][3], four1y0, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][0][3]],
            ['Mai', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][0][4], five1y0, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][0][4]],
            ['Juin', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][0][5], six1y0, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][0][5]],
            ['Juillet', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][0][6], seven1y0, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][0][6]],
            ['Août', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][0][7], eight1y0, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][0][7]],
            ['Septembre', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][0][8], nine1y0, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][0][8]],
            ['Octobre', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][0][9], ten1y0, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][0][9]],
            ['Novembre', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][0][10], eleven1y0, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][0][10]],
            ['Decembre', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][0][11], twelve1y0, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][0][11]]
          ] });
    }
    else if(e.target.value.localeCompare("2021")===0){
      this.setState({ track1: [
            ['Mois', 'Devis', 'Devis transformés en commandes','Commandes'],
            ['Janvier', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][1][0],one1y1, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][1][0]],
            ['Février', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][1][1],two1y1, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][1][1]],
            ['Mars', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][1][2], three1y1, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][1][2]],
            ['Avril', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][1][3], four1y1, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][1][3]],
            ['Mai', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][1][4], five1y1, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][1][4]],
            ['Juin', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][1][5], six1y1, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][1][5]],
            ['Juillet', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][1][6], seven1y1, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][1][6]],
            ['Août', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][1][7], eight1y1, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][1][7]],
            ['Septembre', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][1][8], nine1y1, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][1][8]],
            ['Octobre', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][1][9], ten1y1, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][1][9]],
            ['Novembre', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][1][10], eleven1y1, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][1][10]],
            ['Decembre', this.props.tabData1[this.props.tabGroupe.indexOf(this.state.data1)][1][11], twelve1y1, this.props.tabData3[this.props.tabGroupe.indexOf(this.state.data1)][1][11]]
          ] });
    }

    this.setState({chargement:true});
    
    
  }




   renderSel(){

    return this.props.tabGroupe.map((url,index) => {
      return(
        <option value={url}>{url}</option>
      )
      
    
  });
}

  formatDate(date){
    if(typeof(date)==='number'){
      date=date.toString();
    }
    if(date.length > 5){
        let year = date.substring(0,4);
        let month = date.substring(4,6);
        let day = date.substring(6,8);

    return day+"/"+month+"/"+year;
    }

    return '';
    
   
  
  }

  renderTable(nb,nb2){
    let test = false;
    let style_tr = {backgroundColor:"lightgreen"};
    if(this.state.search){
      return this.renderTable2(nb,nb2);          
    }
    else{
      return this.props.facture_user.map((facture,index) => {
        const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Num_Facture, Montant_Facture, Date_Comptable, Date_Echeance} = facture;
        var date_eche = new Date(Date_Echeance.substring(0,4),Date_Echeance.substring(4,6)-1,Date_Echeance.substring(6,8));
        var diff = new Date() - date_eche;
        diff = diff/(1000 * 60 * 60 * 24);

        if(diff < 30 && diff > 0 ){
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td  title="date d'écheance dépassé de moins de 30 jours" style={{color:"orange"}}>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }

        }
        if(diff >= 30 && diff < 60){
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td title="date d'écheance dépassé de plus de 30 jours" style={{color:'rgb(242,12,173)'}}>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }

        }
        if(diff >= 60 ){
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td title="date d'écheance dépassé de plus de 60 jours" style={{color:"red"}}>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }

        }
        else{
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }

        }
        
        return null;
    });
    }
    
  }


  handleC(data){
    this.props.changeCT(true);
    this.props.changeDT(false);
    this.props.changeTitle( 'Commandes' );
    this.props.changeSuiviP( <Commande  key={"commande"} dataD={data} joinD="true"/> );
    this.props.changeStyleProfil({display:"none"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeBoolP(false);
    this.props.changeColorC(false);
    this.props.changeColorD(true);
  }

  renderTable2(nb,nb2){
    let test = false;
    let mapping = [];
    let i = 0;
    let style_tr = {backgroundColor:"lightgreen"};
    this.props.facture_user.forEach((facture,index) => {
      const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Num_Facture, Montant_Facture, Date_Comptable, Date_Echeance} = facture;
      if((Client_Groupe.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Libelle_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Code_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Commande.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Facture.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Montant_Facture.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || this.formatDate(Date_Comptable).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1  || this.formatDate(Date_Echeance).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1) && id)
      {
        mapping[i] = facture;
        i++;
      }
    });
    while((nb-mapping.length)>15){
      nb-=15;
      nb2-=15;
    }
    return mapping.map((facture,index) => {
      const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Num_Facture, Montant_Facture, Date_Comptable, Date_Echeance} = facture;
      if(Client_Groupe.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Libelle_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Code_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Commande.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Facture.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Montant_Facture.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || this.formatDate(Date_Comptable).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1  || this.formatDate(Date_Echeance).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1)
      {
        var date_eche = new Date(Date_Echeance.substring(0,4),Date_Echeance.substring(4,6)-1,Date_Echeance.substring(6,8));
        var diff = new Date() - date_eche;
        diff = diff/(1000 * 60 * 60 * 24);

        if(diff < 30 && diff > 0 ){
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td  title="date d'écheance dépassé de moins de 30 jours" style={{color:"orange"}}>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }

        }
        if(diff >= 30 && diff < 60){
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td title="date d'écheance dépassé de plus de 30 jours" style={{color:'rgb(242,12,173)'}}>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }

        }
        if(diff >= 60 ){
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td title="date d'écheance dépassé de plus de 60 jours" style={{color:"red"}}>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }

        }
        else{
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }

        }
          if(index<nb && index>=nb2){ 
            return (
              <tr key={id} >
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td style={{textAlign:"left"}}>{Num_Commande}</td>
                <td style={{textAlign:"left"}}>{Num_Facture}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Montant_Facture)}</td>
                <td>{this.formatDate(Date_Comptable)}</td>
                <td>{this.formatDate(Date_Echeance)}</td>
              </tr>
            )
          }
      }
      return null;
    });
    
  }

  handleSuppr(event){

    this.setState({find:''});
   
  }
 
 handlePageS(){
    if(this.state.nb_devis < this.props.facture_user.length){
      let nb_deb = this.state.deb;
      let nb_fin = this.state.nb_devis;
      this.setState({deb:nb_deb+15});
      this.setState({nb_devis:nb_fin+15});
    }
    
  }

  handlePageP(){

    if(this.state.deb >= 15){
      let nb_deb = this.state.deb;
      let nb_fin = this.state.nb_devis;
      this.setState({deb:nb_deb-15});
      this.setState({nb_devis:nb_fin-15});
    }
   
  }

  render() {
    console.log("data1 : "+ this.props.tabData1[0]);

    if(this.state.chargement){
      return (
      <div className="devis">
      
        <div id="graph">
        <select value={this.state.year}  onChange={this.onChange3}  className="bool-select">
          <option value="2020">2020</option>
          <option value="2021">2021</option>
        </select>
        <select value={this.state.data1}  onChange={this.onChange2}  className="bool-select">
          {this.renderSel()}
        </select>
         <Chart
            width={'1500px'}
            height={'700px'}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={this.state.track1}
            options={{
              // Material design options
              chart: {
                title: 'Synthèse annuelle des devis/commandes'
              },
              colors: ['#b0120a', '#ffab91','#e5e4e2'],
            }}
          />
        </div>

      </div>
        );
    }

    return (      
      <div>
        <Chargement/>             
      </div>
      );
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(Reporting);
