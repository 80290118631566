import React, { Component } from 'react';
import '../css/Suivi.css';
import LEFT_SUIVI from './Left_suivi';
import { connect } from "react-redux";

const mapStateToProps = state => {
  return { suivi_page: state.suivi_page };
};

class Misajour extends Component {

  render() {
    return (
    	<div className="maj">
            <h1 style={{fontSize:"1.5em"}}>MAJ X</h1>
            <ul>
              <li>Texte de MAJ</li>
              <li>Texte de MAJ</li>
              <li>Texte de MAJ</li>
            </ul>
            <br/>
            <h1 style={{fontSize:"1.5em"}}>MAJ X</h1>
            <ul>
              <li>Texte de MAJ</li>
              <li>Texte de MAJ</li>
              <li>Texte de MAJ</li>
            </ul>
            
		  </div>
      );
    }
  }
export default connect(mapStateToProps)(Misajour);


