import React, { Component } from 'react';
import '../css/Commande.css';
import { connect } from "react-redux";
import back from '../img/back.svg';
import next from '../img/next.svg';
import back2 from '../img/backback.svg';
import next2 from '../img/nextnext.svg';
import close from '../img/close.png';
import { prestaTele } from '../api/UserFunctions';
import fleche from '../img/fleche.png';
import { changeSuiviP, changeTitle, changeStyleRetour, changeColorD, changeColorR, changeColorF, changeStyleF, changeStyleR, changeFT, changeRT, changeColorC, changeStyleProfil, changeStyleP, changeBoolP, changeStyleD, changeStyleC, changeCT, changeDT} from "../actions/index";

const mapStateToProps = state => {
  return { commande_user:state.commande_user, profile_user: state.profile_user, presta_user: state.presta_user, detail_cmd:state.detail_cmd  };
};

function mapDispatchToProps(dispatch) {
    return {
    changeTitle      : title_header => dispatch(changeTitle(title_header)),
    changeSuiviP     : suivi_page   => dispatch(changeSuiviP(suivi_page)),
    changeStyleP     : style_p      => dispatch(changeStyleP(style_p)),
    changeStyleProfil: style_profil => dispatch(changeStyleProfil(style_profil)),
    changeStyleD     : style_d      => dispatch(changeStyleD(style_d)),
    changeStyleC     : style_c      => dispatch(changeStyleC(style_c)),
    changeStyleF     : style_f      => dispatch(changeStyleF(style_f)),
    changeStyleR     : style_r      => dispatch(changeStyleR(style_r)),
    changeDT         : dt           => dispatch(changeDT(dt)),
    changeCT         : ct           => dispatch(changeCT(ct)),
    changeFT         : ft           => dispatch(changeFT(ft)),
    changeRT         : rt           => dispatch(changeRT(rt)),
    changeBoolP      : boolp        => dispatch(changeBoolP(boolp)),
    changeColorD     : colord       => dispatch(changeColorD(colord)),
    changeColorR     : colorr       => dispatch(changeColorR(colorr)),
    changeColorC     : colorc       => dispatch(changeColorC(colorc)),
    changeColorF     : colorf       => dispatch(changeColorF(colorf)),
    changeStyleRetour: style_retour => dispatch(changeStyleRetour(style_retour))


    };
}

class Detail extends Component {

  constructor(props){
    super(props);
    this.state = {
      deb: 0,
      nb_devis:10,
      search:false,
      find:'',
      setPresta:'',
      stylePresta1:{display:"none"},
      stylePresta2:{display:"none"},
      stylePresta3:{display:"none"},
      u:0,
      DateD:'',
      DateF:'',
      descr:'',
      ref:'',
      all:[],
      testVoir:false,
      styleVoir:{cursor:"hand"}
    }
    this.handlePageS = this.handlePageS.bind(this);
    this.handlePageP = this.handlePageP.bind(this);
    this.handlePageS2 = this.handlePageS2.bind(this);
    this.handlePageP2 = this.handlePageP2.bind(this);
    this.onChange = this.onChange.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.handleCroix1 = this.handleCroix1.bind(this);
    this.handleCroix2 = this.handleCroix2.bind(this);
    this.handleDetails = this.handleDetails.bind(this);
    this.handlePV = this.handlePV.bind(this);
    this.handleSuppr = this.handleSuppr.bind(this);
    this.renderTablePresta = this.renderTablePresta.bind(this);
    this.renderTablePresta2 = this.renderTablePresta2.bind(this);

  }




  handleCroix1(event){
    this.setState({stylePresta1:{display:"none"}});
    this.setState({stylePresta3:{display:"none"}});
  }

  

  handleCroix2(event){
    this.setState({stylePresta3:{display:"none"}});
  }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
    this.setState({search:true});
  }

  formatDate(date){
    let year = date.substring(0,4);
    let month = date.substring(4,6);
    let day = date.substring(6,8);

    return day+"/"+month+"/"+year;
  }



  renderTable(nb,nb2){
      console.log("all "+this.state.all);
      return this.props.detail_cmd.map((commande,index) => {
        let testPresta="Aucune";
        let stylePresta = {color:'black'};
        let styleCursor = {cursor:"hand"};
        const{id, Num_Commande, Code_Art,Designation, Commandee, Livree, HT} = commande;

        

        this.props.presta_user.forEach((presta,index) => {
          if(Num_Commande.localeCompare(presta["No_de_commande"])===0){
            testPresta="Oui";
            stylePresta = {color:"green"};
            styleCursor = {cursor:"pointer"};
          }
        });

        if(index<nb && index>=nb2){
            let total = parseFloat(HT)*parseInt(Commandee);
          return (
            <tr key={id} >
                <td style={{textAlign:"left"}} >{Code_Art}</td>
                <td style={{textAlign:"left"}}>{Designation}</td>
                <td>{Commandee}</td>
                <td>{Livree}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(HT)}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(total)}</td>
              </tr>
             )   
          }
          return null;  
        });
        
            


      
    
  }

  renderTablePresta(){
      let u = 0;
      return this.props.presta_user.map((presta,index) => {
          //this.handleDetails(presta['No_ticket'])
          //this.handlePV(presta['No_ticket'])
            let html = presta['Description_ticket'];
            html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
            html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
            html = html.replace(/<\/div>/ig, '\n');
            html = html.replace(/<\/li>/ig, '\n');
            html = html.replace(/<li>/ig, '  *  ');
            html = html.replace(/<\/ul>/ig, '\n');
            html = html.replace(/<\/p>/ig, '\n');
            html = html.replace(/<br\s*[/]?>/gi, "\n");
            html = html.replace(/<[^>]+>/ig, ''); 
            u+=1;
            let val_voir = "";
            let val_style = {};
            let url = presta["Pdf_url"];
            if(presta["No_de_commande"].localeCompare(this.state.Num)==0){
            if("".localeCompare(presta["Pdf_url"]) == 0){
              val_voir="-";
              val_style={cursor:"hand"};
            }
            else{
              url = url.substring(102, url.length);
              if(url.substring(0,2).localeCompare("PV")===0){
                val_voir="Voir";
                val_style={cursor:"pointer"};
              }
              else{
                val_voir="-";
                val_style={cursor:"hand"};
              }
              
            }
            
                return (
                <tr key={u}>
                  <td className="td_presta">{presta["No_ticket"]}</td>
                  <td className="td_presta">{html}</td>
                  <td className="td_presta">{presta["Date_debut_planifie_action"].substring(0,11)}</td>
                  <td className="td_presta">{presta["Statut"]}</td>
                  <td className="td_presta" onClick={() => this.handlePV()} style={val_style}>{val_voir}</td>
                </tr>  
              ) 
            }
            else{
              return null;
            }
             
        });
        
  }

  renderTablePresta2(){
    if(typeof this.state.doc !== "undefined"){

      let u = 0;
      let t = true;
        u+=1;

        let nomDoc = this.state.doc;
        if ("".localeCompare(nomDoc) !== 0){
          var array = nomDoc.split(",");
          return array.map((url,index) => {
            let first = url
            url = url.substring(102, url.length);
            url = url.replace(/%/ig, ' ');
            url = url.replace(/2B/ig, ' ');
            url = url.replace(/25C3/ig, ' ');
            url = url.replace(/25A9/ig, ' ');
            if(url.substring(0,2).localeCompare("PV")===0){
              t=false;
              return (
                <p key={u} className="prestaNum3" onClick={() => this.handleTele(first)}>{url.substring(0,url.length-4)}</p>  
              )  
            }
            else{
              return null;
            }
          });
          }
          else{
            return null;

        }
      
    }
      

    
    return null; 
  }

  handleTele(link){
    prestaTele(link).then(res => {
          });
  }

  handleDetails(test){
    this.setState({stylePresta2:{display:"block"}});
    this.setState({stylePresta3:{display:"none"}});
      this.props.presta_user.forEach((presta,index) => {
          if(presta['No_ticket'].localeCompare(test)===0){
            this.setState({DateD:presta['Date_debut_planifie_action']});
            let html = presta['Description_ticket'];
            html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
            html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
            html = html.replace(/<\/div>/ig, '\n');
            html = html.replace(/<\/li>/ig, '\n');
            html = html.replace(/<li>/ig, '  *  ');
            html = html.replace(/<\/ul>/ig, '\n');
            html = html.replace(/<\/p>/ig, '\n');
            html = html.replace(/<br\s*[/]?>/gi, "\n");
            html = html.replace(/<[^>]+>/ig, '');
            this.setState({descr:html});
          }
        
        });

    
  }

  handlePV(){
    this.setState({stylePresta3:{display:"block"}});
    this.setState({stylePresta2:{display:"none"}});
    this.props.presta_user.forEach((presta,index) => {
      if(this.state.Num.localeCompare(presta["No_de_commande"])==0){
        this.setState({doc:presta['Pdf_url']});
      }
    });
  }

  handlePresta(presta1){
    this.setState({Num:presta1})
    this.setState({stylePresta1:{display:"block"}})
    
    
  }


  renderTable2(nb,nb2){
    let mapping = [];
    let i = 0;
    /*if(typeof this.props.dataD !== 'undefined'){
      this.props.commande_user.forEach((commande,index) => {
        const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Ref_Client, Date_Saisie_Commande, Num_Devis, Statut_Commande, HT, TTC} = commande;
        if((Num_Devis.toUpperCase().indexOf(this.props.dataD.toUpperCase()) !== -1) && id)
        {
          mapping[i] = commande;
          i++;
        }
      });
    }
    else{
      this.props.commande_user.forEach((commande,index) => {
        const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Ref_Client, Date_Saisie_Commande, Num_Devis, Statut_Commande, HT, TTC} = commande;
        console.log("client "+Client_Groupe+" "+Libelle_Client+" "+Code_Client+" "+Num_Commande+" "+Ref_Client+" "+Date_Saisie_Commande+" "+Num_Devis+" "+Statut_Commande+" "+HT+" "+TTC)
        if((Client_Groupe.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Libelle_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Code_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Commande.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1  || Ref_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || this.formatDate(Date_Saisie_Commande).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Devis.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Statut_Commande.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || HT.toString().toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || TTC.toString().toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1) && id)
        {
          mapping[i] = commande;
          i++;
        }
      });
    }
    while((nb-mapping.length)>15){
      nb-=15;
      nb2-=15;
    }
    return mapping.map((commande,index) => {
      const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Ref_Client, Date_Saisie_Commande, Num_Devis, Statut_Commande, HT, TTC} = commande;
      if( (Client_Groupe.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Libelle_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Code_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Commande.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Ref_Client.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || this.formatDate(Date_Saisie_Commande).toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Num_Devis.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || Statut_Commande.toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || HT.toString().toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1 || TTC.toString().toUpperCase().indexOf(this.state.find.toUpperCase()) !== -1) && id)
      {
        let testPresta="Aucune";
        let stylePresta = {color:"black"};
        let styleCursor = {cursor:"hand"};
        const{id, Client_Groupe, Libelle_Client, Code_Client, Num_Commande, Ref_Client, Date_Saisie_Commande, Num_Devis, Statut_Commande, HT, TTC} = commande;

        this.props.presta_user.forEach((presta,index) => {
          if(Num_Commande.localeCompare(presta["No_de_commande"])===0 && ("Projet".localeCompare(presta["Groupe_affectation"])===0 || "Infrastructure".localeCompare(presta["Groupe_affectation"]))){
            testPresta="Oui";
            stylePresta = {color:"green"};
            styleCursor = {cursor:"pointer"};
          }
        });
          
          if(index<nb && index>=nb2){     
            return (
              <tr key={id}>
                <td>{Client_Groupe}</td>
                <td style={{textAlign:"left"}}>{Libelle_Client}</td>
                <td>{Code_Client}</td>
                <td>{Num_Commande}</td>
                <td>{Ref_Client}</td>
                <td>{this.formatDate(Date_Saisie_Commande)}</td>
                <td>{Num_Devis}</td>
                <td>{Statut_Commande}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(HT)}</td>
                <td style={{textAlign:"right"}}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(TTC)}</td>
              </tr>
            )
          }
        return null;
      }
      return null;
    });*/
  }

  handlePageS(){
    if(this.state.nb_devis < this.props.detail_cmd.length){
      let nb_deb = this.state.deb;
      let nb_fin = this.state.nb_devis;
      this.setState({deb:nb_deb+10});
      this.setState({nb_devis:nb_fin+10});
    }
    
  }

  handlePageP(){

    if(this.state.deb >= 10){
      let nb_deb = this.state.deb;
      let nb_fin = this.state.nb_devis;
      this.setState({deb:nb_deb-10});
      this.setState({nb_devis:nb_fin-10});
    }
   
  }

  handleSuppr(event){

    this.setState({find:''});
   
  }

  handlePageS2(){
    this.setState({deb:this.props.detail_cmd.length-10});
    this.setState({nb_devis:this.props.detail_cmd.length});
  
}

handlePageP2(){
    this.setState({deb:0});
    this.setState({nb_devis:10});
 
}
  
  handleTri(){
    if(this.state.triage === 0){
        this.props.commande_user.sort(function compare(a,b) {
            var string1 = a.Date_Saisie_Commande.toString();
            var string2 = b.Date_Saisie_Commande.toString();
            var dateA = new Date(parseInt(string1.substring(0,4)), parseInt(string1.substring(4,6)),parseInt(string1.substring(6,8)));
            var dateB = new Date(parseInt(string2.substring(0,4)), parseInt(string2.substring(4,6)),parseInt(string2.substring(6,8)));
        return dateA - dateB;
        });
        this.setState({triage:1});
    }
    else{
        this.props.commande_user.sort(function compare(b,a) {
            var string1 = a.Date_Saisie_Commande.toString();
            var string2 = b.Date_Saisie_Commande.toString();
            var dateA = new Date(parseInt(string1.substring(0,4)), parseInt(string1.substring(4,6)),parseInt(string1.substring(6,8)));
            var dateB = new Date(parseInt(string2.substring(0,4)), parseInt(string2.substring(4,6)),parseInt(string2.substring(6,8)));
        return dateA - dateB;
        });

        this.setState({triage:0});
    }
    

    }

  render() {

    var validation = false;
    this.props.presta_user.forEach((presta,index) => {
      if(this.props.dataD.num.localeCompare(presta["No_de_commande"])==0){
        validation = true;
      }
    });
    

    if(validation){
        return (

            <div className="commande">
             {this.state.find.localeCompare("")==0?null:<img id="croix_cmd3" onClick={(event) => this.handleSuppr(event)} src={close} alt="croix"/>}
        
              <table >
                <thead id="table_d">
                  <tr>
                    <th>GROUPE</th>
                    <th>LIBELL&Eacute; CLIENT</th> 
                    <th>CODE<br/>CLIENT</th>
                    <th>N°<br/>COMMANDE</th>
                    <th>REF CDE<br/>CLIENT</th>
                    <th onClick={(event) => this.handleTri(event)} style={{cursor:"pointer"}}><span title="Tri par date">DATE COMMANDE<img  id="fleche2" src={fleche} alt="fleche"/></span></th>
                    <th>N°<br/>DE DEVIS</th>
                    <th>STATUT DE LA<br/>COMMANDE</th>
                    <th>MONTANT<br/>HT</th>
                    <th>MONTANT<br/>TTC</th>
                  </tr>
                </thead>
                <tbody>
                    <tr className="test" onClick={() => this.handlePresta(this.props.dataD.num)} >
                        <td>{this.props.dataD.groupe}</td>
                        <td style={{textAlign:"left"}}>{this.props.dataD.libelle}</td>
                        <td>{this.props.dataD.code}</td>
                        <td>{this.props.dataD.num}</td>
                        <td>{this.props.dataD.ref}</td>
                        <td>{this.props.dataD.date}</td>
                        <td>{this.props.dataD.numd}</td>
                        <td>-</td>
                        <td style={{textAlign:"right"}}>{this.props.dataD.ht}</td>
                        <td style={{textAlign:"right"}}>{this.props.dataD.ttc}</td>
                      </tr>
                </tbody>
              </table>
              <table id="tdetail" >
                <thead id="table_d">
                  <tr>
                    <th>CODE ARTICLE</th>
                    <th>D&Eacute;SIGNATION ARTICLE</th> 
                    <th>QUANTIT&Eacute; COMMAND&Eacute;E</th>
                    <th>QUANTIT&Eacute; LIVR&Eacute;E</th>
                    <th>PRIX UNITAIRE HT</th>
                    <th>TOTAL LIGNE HT</th>
                  </tr>
                </thead>
                <tbody>
                 {this.renderTable(this.state.nb_devis,this.state.deb)}
                </tbody>
              </table>
              {this.props.detail_cmd.length > 15 ? <div id="page_c">
              <img id="pagePD" onClick={(event) => this.handlePageP2(event)} src={back2} alt="Logo back"/>
              <img id="pagePD22" onClick={(event) => this.handlePageP(event)} src={back} alt="Logo back"/>
                <img id="pageSD22" onClick={(event) => this.handlePageS(event)} src={next} alt="Logo next"/>
                <img id="pageSD" onClick={(event) => this.handlePageS2(event)} src={next2} alt="Logo next"/>
              </div>: null}
              
        
              <div id="modalPresta1" style={this.state.stylePresta1} >
                    <div className="modal-content1" >
                      <p id="forget_title1">Actions prévues<img id="croix_cmd" onClick={(event) => this.handleCroix1(event)} src={close} alt="croix"/></p>
                      <div id="detail_content">
                        <table >
                          <thead>
                            <tr>
                              <th className="th_cmd">N° de ticket</th>
                              <th className="th_cmd">Déscriptif</th> 
                              <th className="th_cmd">Date d'intervention</th>
                              <th className="th_cmd">Statut</th>
                              <th className="th_cmd">Pièce jointe</th>
                            </tr>
                          </thead>
                          <tbody id="table_presta">
                          {this.renderTablePresta()}
                          </tbody>
                        </table>
                      </div>
                      
                    </div>      
              </div>
              <div id="modalPresta3" style={this.state.stylePresta3} >
                      <p id="forget_title3">Procès-verbaux<img id="croix_cmd2" onClick={(event) => this.handleCroix2(event)} src={close} alt="croix"/></p>
                      {this.renderTablePresta2()}   
              </div>
            </div>
            );
    }
    else{
        return (

            <div className="commande">
             {this.state.find.localeCompare("")==0?null:<img id="croix_cmd3" onClick={(event) => this.handleSuppr(event)} src={close} alt="croix"/>}
        
              <table >
                <thead id="table_d">
                  <tr>
                    <th>GROUPE</th>
                    <th>LIBELL&Eacute; CLIENT</th> 
                    <th>CODE<br/>CLIENT</th>
                    <th>N°<br/>COMMANDE</th>
                    <th>REF CDE<br/>CLIENT</th>
                    <th onClick={(event) => this.handleTri(event)} style={{cursor:"pointer"}}><span title="Tri par date">DATE COMMANDE<img  id="fleche2" src={fleche} alt="fleche"/></span></th>
                    <th>N°<br/>DE DEVIS</th>
                    <th>STATUT DE LA<br/>COMMANDE</th>
                    <th>MONTANT<br/>HT</th>
                    <th>MONTANT<br/>TTC</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{this.props.dataD.groupe}</td>
                        <td style={{textAlign:"left"}}>{this.props.dataD.libelle}</td>
                        <td>{this.props.dataD.code}</td>
                        <td>{this.props.dataD.num}</td>
                        <td>{this.props.dataD.ref}</td>
                        <td>{this.props.dataD.date}</td>
                        <td>{this.props.dataD.numd}</td>
                        <td>-</td>
                        <td style={{textAlign:"right"}}>{this.props.dataD.ht}</td>
                        <td style={{textAlign:"right"}}>{this.props.dataD.ttc}</td>
                      </tr>
                </tbody>
              </table>
              <table id="tdetail" >
                <thead id="table_d">
                  <tr>
                    <th>CODE ARTICLE</th>
                    <th>D&Eacute;SIGNATION ARTICLE</th> 
                    <th>QUANTIT&Eacute; COMMAND&Eacute;E</th>
                    <th>QUANTIT&Eacute; LIVR&Eacute;E</th>
                    <th>PRIX UNITAIRE HT</th>
                    <th>TOTAL LIGNE HT</th>
                  </tr>
                </thead>
                <tbody>
                 {this.renderTable(this.state.nb_devis,this.state.deb)}
                </tbody>
              </table>
              {this.props.detail_cmd.length > 15 ? <div id="page_c">
              <img id="pagePD" onClick={(event) => this.handlePageP2(event)} src={back2} alt="Logo back"/>
              <img id="pagePD22" onClick={(event) => this.handlePageP(event)} src={back} alt="Logo back"/>
                <img id="pageSD22" onClick={(event) => this.handlePageS(event)} src={next} alt="Logo next"/>
                <img id="pageSD" onClick={(event) => this.handlePageS2(event)} src={next2} alt="Logo next"/>
              </div>: null}
        
              <div id="modalPresta1" style={this.state.stylePresta1} >
                    <div className="modal-content1" >
                      <p id="forget_title1">Actions prévues<img id="croix_cmd" onClick={(event) => this.handleCroix1(event)} src={close} alt="croix"/></p>
                      <div id="detail_content">
                        <table >
                          <thead>
                            <tr>
                              <th className="th_cmd">N° de ticket</th>
                              <th className="th_cmd">Déscriptif</th> 
                              <th className="th_cmd">Date d'intervention</th>
                              <th className="th_cmd">Statut</th>
                              <th className="th_cmd">Pièce jointe</th>
                            </tr>
                          </thead>
                          <tbody id="table_presta">
                          {this.renderTablePresta()}
                          </tbody>
                        </table>
                      </div>
                      
                    </div>      
              </div>
              <div id="modalPresta3" style={this.state.stylePresta3} >
                      <p id="forget_title3">Procès-verbaux<img id="croix_cmd2" onClick={(event) => this.handleCroix2(event)} src={close} alt="croix"/></p>
                      {this.renderTablePresta2()}   
              </div>
            </div>
            );
    }
    
      

    //
    
    }

  }
export default connect(mapStateToProps, mapDispatchToProps)(Detail);
