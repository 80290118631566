import React, { Component } from 'react';
import '../css/Left_suivi.css';
import { connect } from "react-redux";
import { changeSuiviP, changeTitle, changeColorD, changeChoixAdm, changeColorF, changeStyleF, changeFT, changeColorC, changeStyleProfil, changeStyleP, changeBoolP, changeStyleD, changeStyleC, changeCT, changeDT} from "../actions/index";
import Create_logo from "./Create_logo";
import List_logo from "./List_logo";
import List_users from "./List_users";
import Create_user from "./Create_user";
import logocfi from '../img/logo_cfi_grey_p.svg';


const mapStateToProps = state => {
  return { suivi_status: state.suivi_status, boolp:state.boolp,style_d: state.style_d, style_c: state.style_c, style_f: state.style_f, style_profil:state.style_profil, style_p:state.style_p, ct:state.ct, dt:state.dt, colorf:state.colorf, ft:state.ft, colord: state.colord, colorc: state.colorc, colorp: state.colorp };
};


function mapDispatchToProps(dispatch) {
  	return {
  		changeTitle: title_header => dispatch(changeTitle(title_header)),
      changeSuiviP: suivi_page => dispatch(changeSuiviP(suivi_page)),
      changeStyleP: style_p => dispatch(changeStyleP(style_p)),
      changeStyleD: style_d => dispatch(changeStyleD(style_d)),
      changeStyleC: style_c => dispatch(changeStyleC(style_c)),
      changeStyleF: style_f => dispatch(changeStyleF(style_f)),
      changeStyleProfil: style_profil => dispatch(changeStyleProfil(style_profil)),
      changeChoixAdm: choix_adm => dispatch(changeChoixAdm(choix_adm)),
      changeDT: dt => dispatch(changeDT(dt)),
      changeCT: ct => dispatch(changeCT(ct)),
      changeFT: ft => dispatch(changeFT(ft)),
      changeBoolP: boolp => dispatch(changeBoolP(boolp)),
      changeColorD: colord => dispatch(changeColorD(colord)),
      changeColorC: colorc => dispatch(changeColorC(colorc)),
      changeColorF: colorf => dispatch(changeColorF(colorf))


  	};
}


class Left_admin extends Component {

  constructor(props){
    super(props);

    this.state = {
    	d:this.props.style_d,
    	c:this.props.style_c
    	};

    this.handleP = this.handleP.bind(this);
    this.handleD = this.handleD.bind(this);
    this.handleC = this.handleC.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
    this.toggleHover2 = this.toggleHover2.bind(this);
    this.toggleHoverL = this.toggleHoverL.bind(this);
    this.toggleHover2L = this.toggleHover2L.bind(this);
    this.toggleHover3 = this.toggleHover3.bind(this);
    this.toggleHover3L = this.toggleHover3L.bind(this);
    this.toggleHover4 = this.toggleHover4.bind(this);
    this.toggleHover4L = this.toggleHover4L.bind(this);
    
  }


  toggleHover4() {
    this.props.changeStyleP({backgroundColor:"#eeeeee"});
  }

  toggleHover4L() {
    if(this.props.dt || this.props.ct || this.props.ft){
      this.props.changeStyleP({backgroundColor:"#f5f6f8"});
    }  
  }


  toggleHover3() {
    this.props.changeStyleF({backgroundColor:"#eeeeee"});
  }

  toggleHover3L() {
    if(this.props.dt || this.props.ct || this.props.boolp){
      this.props.changeStyleF({backgroundColor:"#f5f6f8"});
    }  
  }

  toggleHover() {
    this.props.changeStyleD({backgroundColor:"#eeeeee"});
  }


  toggleHover2() {
    this.props.changeStyleC({backgroundColor:"#eeeeee"});
  }

    toggleHoverL() {
    if(this.props.ft || this.props.ct || this.props.boolp){
      this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    }
    
   
  }

  toggleHover2L() {
    if(this.props.ft || this.props.dt || this.props.boolp){
      this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    }  
  }





  handleD(event){
    this.props.changeDT(true);
    this.props.changeCT(false);
    this.props.changeFT(false);
    this.props.changeBoolP(false);
  	this.props.changeChoixAdm( <Create_user  key={"create_user"} /> );
    this.props.changeStyleD({backgroundColor:"#eeeeee"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleF({backgroundColor:"#f5f6f8"});
    this.props.changeStyleP({backgroundColor:"#f5f6f8"}); 
    this.props.changeColorD(false);
    this.props.changeColorC(true);
    this.props.changeColorF(true);

  }

  handleC(event){
    this.props.changeCT(true);
    this.props.changeDT(false);
    this.props.changeFT(false);
    this.props.changeBoolP(false);
  	this.props.changeChoixAdm( <Create_logo  key={"create_logo"} /> );
    this.props.changeStyleC({backgroundColor:"#eeeeee"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeStyleF({backgroundColor:"#f5f6f8"});
    this.props.changeStyleP({backgroundColor:"#f5f6f8"}); 
    this.props.changeColorC(false);
    this.props.changeColorD(true);
    this.props.changeColorF(true);
  }

   handleF(event){
    this.props.changeFT(true);
    this.props.changeCT(false);
    this.props.changeDT(false);
    this.props.changeBoolP(false);
    this.props.changeChoixAdm( <List_users  key={"list_users"} /> );
    this.props.changeStyleF({backgroundColor:"#eeeeee"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeStyleP({backgroundColor:"#f5f6f8"}); 
    this.props.changeColorF(false);
    this.props.changeColorC(true);
    this.props.changeColorD(true);

  }

  handleP(event){
    this.props.changeChoixAdm( <List_logo key={"list_logo"} /> );
    this.props.changeStyleP({backgroundColor:"#eeeeee"});
    this.props.changeStyleD({backgroundColor:"#f5f6f8"});
    this.props.changeStyleC({backgroundColor:"#f5f6f8"});
    this.props.changeStyleF({backgroundColor:"#f5f6f8"});
    this.props.changeBoolP(true);
    this.props.changeFT(false);
    this.props.changeCT(false);
    this.props.changeDT(false);
    this.props.changeColorD(true);
    this.props.changeColorC(true);

  }

  render() {
    return (
	    <div id="left_suivi">
    		<p id="devis_p"  style={Object.assign({}, this.props.style_d, this.props.colord)} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHoverL} onClick={(event) => this.handleD(event)}>
Ajouter utilisateur</p>
        <p id="facture_p"  style={Object.assign({}, this.props.style_f, this.props.colorf) } onClick={(event) => this.handleF(event)} onMouseEnter={this.toggleHover3} onMouseLeave={this.toggleHover3L}>Liste utilisateurs</p>
	      <footer id="ver"><p id="realised"><img id="logo_cfi_grey" src={logocfi} alt="logo cfi"/>2021 - Version 8.11.21.1</p></footer>
		  </div>
      );
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(Left_admin);
