import React, { Component } from 'react';
import '../css/Accueil.css';
import Suivi from "./Suivi";
import Devis from "./Devis";
import Facture from "./Facture";
import Reporting from "./Reporting";
import pro from "../img/pro.jpg";
import logo from "../img/logo-hauts.png";
import logo2 from "../img/sipperec.jpg"
import logo3 from "../img/image-1597006702674.jpg"
import d from "../img/btn_devis.png";
import c from "../img/btn_commande.png";
import f from "../img/btn_facture_acc.png";
import r from "../img/btn_reporting_acc.png";
import { connect } from "react-redux";
import { changeStatus, changeStyleRetour, changeSuiviP, changeRT, changeFT, changeColorF, changeColorR, changeStyleF, changeStyleR, changeColorD, changeColorC, changeTitle, changeStyleD, changeStyleC, changeDT, changeCT} from "../actions/index";

const mapStateToProps = state => {
  return { profile_user:state.profile_user, img_list:state.img_list, img_get:state.img_get, commande_user:state.commande_user  };
};

function mapDispatchToProps(dispatch) {
  	return {
    	changeStatus: suivi_status => dispatch(changeStatus(suivi_status)),
      changeTitle: title_header => dispatch(changeTitle(title_header)),
      changeSuiviP: suivi_page => dispatch(changeSuiviP(suivi_page)),
      changeStyleD: style_d => dispatch(changeStyleD(style_d)),
      changeStyleC: style_c => dispatch(changeStyleC(style_c)),
      changeStyleF: style_f => dispatch(changeStyleF(style_f)),
      changeStyleR: style_r => dispatch(changeStyleR(style_r)),
      changeDT: dt => dispatch(changeDT(dt)),
      changeCT: ct => dispatch(changeCT(ct)),
      changeFT: ft => dispatch(changeFT(ft)),
      changeRT: rt => dispatch(changeRT(rt)),
      changeColorD: colord => dispatch(changeColorD(colord)),
      changeColorC: colorc => dispatch(changeColorC(colorc)),
      changeColorR: colorr => dispatch(changeColorR(colorr)),
      changeColorF: colorf => dispatch(changeColorF(colorf)),
      changeStyleRetour: style_retour => dispatch(changeStyleRetour(style_retour))
  	};
}

class Accueil extends Component {

	constructor(props){
    	super(props);
    	this.state = {
        img_etps:null,
	      etps_final:null
    	};
    	this.handleStatusD = this.handleStatusD.bind(this);
    	this.handleStatusC = this.handleStatusC.bind(this);
      console.log(this.props.img_list);

  	}

    handleStatusR(event) {
      this.props.changeStatus( <Suivi component={Suivi}  key={"suivi"} /> );
      this.props.changeSuiviP( <Reporting  key={"reporting"} /> );
      this.props.changeTitle( 'Reporting' );
      this.props.changeStyleR({backgroundColor:"#eeeeee"});
      this.props.changeStyleF({backgroundColor:"#f5f6f8"});
      this.props.changeStyleD({backgroundColor:"#f5f6f8"});
      this.props.changeStyleC({backgroundColor:"#f5f6f8"});
      this.props.changeStyleRetour({display:"none"});
      this.props.changeRT(true);
      this.props.changeFT(false);
      this.props.changeDT(false);
      this.props.changeCT(false);
      this.props.changeColorR(false);
      this.props.changeColorF(true);
      this.props.changeColorD(true);
      this.props.changeColorC(true);
    }

    handleStatusF(event) {
      this.props.changeStatus( <Suivi component={Suivi}  key={"suivi"} /> );
      this.props.changeSuiviP( <Facture  key={"factures"} /> );
      this.props.changeTitle( 'Factures / Avoirs non lettrés' );
      this.props.changeStyleF({backgroundColor:"#eeeeee"});
      this.props.changeStyleR({backgroundColor:"#f5f6f8"});
      this.props.changeStyleD({backgroundColor:"#f5f6f8"});
      this.props.changeStyleC({backgroundColor:"#f5f6f8"});
      this.props.changeStyleRetour({display:"none"});
      this.props.changeFT(true);
      this.props.changeRT(false);
      this.props.changeDT(false);
      this.props.changeCT(false);
      this.props.changeColorF(false);
      this.props.changeColorR(true);
      this.props.changeColorD(true);
      this.props.changeColorC(true);
    }

  	handleStatusC(event) {
     	this.props.changeStatus( <Suivi component={Suivi}  key={"suivi"} /> );
      this.props.changeTitle( 'Commandes' );
      this.props.changeStyleC({backgroundColor:"#eeeeee"});
      this.props.changeStyleR({backgroundColor:"#f5f6f8"});
      this.props.changeStyleD({backgroundColor:"#f5f6f8"});
      this.props.changeStyleF({backgroundColor:"#f5f6f8"});
      this.props.changeStyleRetour({display:"none"});
      this.props.changeCT(true);
      this.props.changeRT(false);
      this.props.changeDT(false);
      this.props.changeFT(false);
      this.props.changeColorC(false);
      this.props.changeColorR(true);
      this.props.changeColorD(true);
      this.props.changeColorF(true);
  	}


  	handleStatusD(event) {
    	this.props.changeStatus( <Suivi component={Suivi}  key={"suivi"} /> );
      this.props.changeSuiviP( <Devis  key={"devis"} /> );
      this.props.changeTitle( 'Devis' );
      this.props.changeStyleD({backgroundColor:"#eeeeee"});
      this.props.changeStyleC({backgroundColor:"#f5f6f8"});
      this.props.changeStyleF({backgroundColor:"#f5f6f8"});
      this.props.changeStyleR({backgroundColor:"#f5f6f8"});
      this.props.changeStyleRetour({display:"none"});
      this.props.changeDT(true);
      this.props.changeCT(false);
      this.props.changeFT(false);
      this.props.changeRT(false);
      this.props.changeColorD(false);
      this.props.changeColorC(true);
      this.props.changeColorF(true);
      this.props.changeColorR(true);
  	}

	

  	render() {

        let test = true;
       


        let adr = "";
        this.props.img_list.forEach((presta,index) => {
          if(presta.etps.localeCompare(this.props.profile_user.etps) === 0){
            adr = presta.img;
          }
        });

        if(adr.localeCompare("")===0){
          test=false;
        }

    	return (

      	<div id="console" style={this.state.suivi_status}>
      		<div id="vide">
      			<div id="div_pro">
      				<img id="pro" src={pro} alt="accueil"/>
      				<div id="div_pro_txt">
	      				<p id="msg_bvn">Bienvenue dans votre plateforme de suivi de commandes, solutions et services.</p>
		      			<div id="div_d">
                <div id="pos-d">
		      				<img id="d" src={d} alt="devis" onClick={(event) => this.handleStatusD(event)}/></div>
		      				<div id="d_txt">
		      					<h5 id="title_d">Devis</h5>
		      				</div>
		      			</div>
		      			<div id="div_c">
                <div id="pos-c">
		      				<img id="c" src={c} alt="
                  commande" onClick={(event) => this.handleStatusC(event)}/> </div>
		      				<div id="c_txt">
		      					<h5 id="title_c">Commandes</h5>
		      				</div>
		      			</div>
                <div id="div_c">
                <div id="pos-c">
                  <img id="f" src={f} alt="facture" onClick={(event) => this.handleStatusF(event)}/> </div>
                  <div id="c_txt">
                    <h5 id="title_c">Factures / Avoirs non lettrés</h5>
                  </div>
                </div>
                <div id="div_c">
                <div id="pos-c">
                  <img id="r" src={r} alt="reporting" onClick={(event) => this.handleStatusR(event)} /> </div>
                  <div id="c_txt">
                    <h5 id="title_c">Reporting</h5>
                  </div>
                </div>
		      		</div>
              {test ? <img id="logo_sipperec" src={process.env.REACT_APP_API+'/users/display/'+adr} alt="Logo etps"/>  : null}
              
		      		
	      		</div>

	      	</div>

	      </div>
	      );
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Accueil);
